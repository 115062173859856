<template>
  <div :class="{ 'tw-bg-white': isMemberProfileUrl }">
    <eslite-header :is-for-pages="true" />
    <ul class="category-nav tw-container tw-p-0 tw-flex">
      <li>
        <a href="javascript: void(0);">全站分類</a>
        <MenuComponent :rwd-mode="true" class="submenu" />
      </li>
      <li v-for="item in navTabs" :key="`pages-header-nav-${item.alt}`" class="nav-links">
        <router-link-usage :link="item.link" :title="item.alt">{{ item.alt }}</router-link-usage>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import MenuComponent from '@/components/layout/elements/menu-component.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import EsliteHeader from '@/components/layout/header-2024index.vue';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { memberProfileStyle } from '@/constant/member-profile';

const route = useRoute();
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));

const homeAdStore = useHomeAd2024Store();
const { fetchHomeAd } = homeAdStore;
const { getTopBanner, getHeaderSmallBanner, getSearchKeywords, getBigSlideTabs, getMenu } = storeToRefs(homeAdStore);

/**
 * top banner
 * @const {computed({})} topBannrImageSource 置頂 banner data
 */
const topBannrImageSource = computed(() => getTopBanner.value);

/**
 * header small banner
 * @const {computed({})} smallBannrImageSource header small banner data
 */
const smallBannrImageSource = computed(() => getHeaderSmallBanner.value);

const searchKeywords = computed(() => getSearchKeywords.value?.items || []);

const navTabs = computed(() => getBigSlideTabs.value?.items || []);
const menuData = computed(() => getMenu.value?.items || []);

onMounted(async () => {
  const defaultFace = [];

  if (Object.keys(topBannrImageSource.value).length === 0) {
    defaultFace.push(homePageADTypeEnum.topBanner);
  }
  if (searchKeywords.value.length === 0) {
    defaultFace.push(homePageADTypeEnum.searchKeywords);
  }
  if (Object.keys(smallBannrImageSource.value).length === 0) {
    defaultFace.push(homePageADTypeEnum.headerSmallBanner);
  }
  if (menuData.value.length === 0) {
    defaultFace.push(homePageADTypeEnum.menu);
  }
  if (navTabs.value.length === 0) {
    defaultFace.push(homePageADTypeEnum.bigSlideTabs);
  }
  if (defaultFace.length === 0) return false;
  await fetchHomeAd(defaultFace);
  return true;
});
</script>

<script>
export default { name: 'PagesHeader2024Desktop' };
</script>

<style scoped>
.category-nav {
  .nav-links a {
    @apply tw-no-underline;
  }
  &:hover .nav-links a {
    @apply tw-no-underline;
  }
  li {
    @apply tw-relative;

    &:hover {
      > a:after {
        @apply tw-block tw-h-1 tw-w-full;
      }
      .submenu {
        @apply tw-block;
      }
    }
    a {
      color: theme('colors.gray-800');
      cursor: pointer;
    }
    > a {
      @apply tw-py-2 tw-px-4 tw-block;
      &:after {
        content: '';
        @apply tw-block tw-h-1 tw-w-0 tw-absolute tw-left-0 tw-bottom-0 tw-bg-gray-900;
        transition: all 0.3s;
      }
    }
    &:not(:last-child) {
      @apply tw-relative;
      &:after {
        content: '';
        @apply tw-block tw-h-4 tw-w-[1px] tw-absolute tw-right-0 tw-top-3 tw-bg-gray-200;
      }
    }

    .submenu {
      @apply tw-hidden tw-absolute tw-top-[100%] tw-left-0 tw-border tw-border-solid tw-border-gold-500 tw-py-6 tw-bg-white tw-z-10 tw-w-[212px];
    }
  }
}

.line {
  border-top: 1px solid #f0ebe1;
}

.brand-img {
  width: 40px;
  height: 40px;
}

.banner-img {
  width: 250px;
  height: 80px;
}
</style>
