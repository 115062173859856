<template>
  <div v-if="tabs.length > 0" class="tw-bg-white tw-mb-4">
    <div class="title">{{ title }}</div>
    <ul v-if="tabs?.length > 0" class="tabs">
      <li
        v-for="(tab, index) in formattedTabs"
        :id="tab.index !== undefined ? `home-recommend-tags-${tab.index + 1}` : ''"
        :key="index"
        :class="{ active: currentTab === tab.index, empty: !tab.title }"
        @click="tab.index !== undefined && selectTab(tab.index)"
      >
        {{ tab.title || '' }}
      </li>
    </ul>
    <div v-if="!$isEmpty(currentTabData?.products)" class="banner">
      <router-link-usage :id="`home-recommend-sb-${currentTab + 1}`" :link="currentTabData.link" :title="currentTabData.alt"
        ><img :src="currentTabData.image" :title="currentTabData.alt"
      /></router-link-usage>
    </div>
    <div v-if="isTabActive(currentTab) && !$isEmpty(currentTabData?.products)" :style="`background-color: ${currentTabData?.color};`" class="products">
      <div v-for="(product, index) in currentTabData?.products" :key="product.pid" class="product">
        <card :product="product" :mode-auto="true" :id-data="`home-recommend-products-${currentTab + 1}-${index + 1}`" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import Card from '@/components/product/card-index2024.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { isEmptyValue } from '@/helper/data-process';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
  tabs: { type: Array, required: true },
});

const { title, tabs } = toRefs(props);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const first = ref(true);
const currentTab = ref(0);
const loadedTabs = ref(new Set());

const getProductPrices = () => {
  const ids = map((x) => x.id || x.guid || x.product_guid || x.esliteSN, tabs.value[currentTab.value]?.products || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const currentTabData = computed(() => tabs.value[currentTab.value]);

const selectTab = (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return false;
  currentTab.value = index;
  loadedTabs.value.add(index);
  getProductPrices();
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (isEmptyValue(nv) || tabs.value?.length <= 0) return false;
  currentTab.value = first.value ? randomIfNotTest(tabs.value?.length - 1, 0) : 0;
  if (first.value === true) first.value = false;
  selectTab(currentTab.value, false);
  return true;
};

const isTabActive = (index) => loadedTabs.value.has(index);

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.name, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.name, index }));
};

const formattedTabs = computed(() => insertSpaces(tabs.value));

onMounted(() => {
  watch(tabs, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.title {
  @apply tw-text-[20px] tw-leading-7 tw-font-bold tw-px-4 tw-py-5;
  color: theme('colors.gray-800');
}

.tabs {
  @apply tw-px-4 tw-pb-5 tw-mb-0 tw-flex tw-overflow-auto tw-list-none;
}
.tabs li {
  @apply tw-p-[8px] tw-text-[14px] tw-leading-[22px] tw-font-medium tw-rounded tw-cursor-pointer tw-shrink-0;
  color: theme('colors.gray-500');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    @apply tw-cursor-default tw-leading-[18px];
    color: theme('colors.gray-200');
    &::before {
      @apply tw-text-[10px];
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}
.products-wrapper {
  @apply tw-overflow-auto;
}

.products {
  @apply tw-w-full tw-grid tw-grid-cols-3 tw-gap-2 tw-pt-6 tw-pb-6 tw-px-4;
}

.product {
  @apply tw-col-span-1;
}

.banner {
  @apply tw-flex tw-w-full;
  a,
  img {
    @apply tw-w-full tw-m-0 tw-p-0;
  }
}
</style>
