<template>
  <div class="products">
    <card v-for="(product, pid) in products" :key="product.pid" :id-data="productID(pid)" :product="product" />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import Card from '@/components/product/card-index2024.vue';
import { isEmptyValue } from '@/helper/data-process';

const props = defineProps({
  title: { type: String, default: '' },
  products: { type: Array, required: true },
  tabData: { type: String, default: '' }, // tag 欄位的 ga ID 標籤
  productData: { type: String, default: '' }, // product 欄位的 ga ID 標籤
  currentTab: { type: Number, default: 0 },
});

const { products, tabData, productData, currentTab } = toRefs(props);

/** @const {method} productID 商品 id */
const productID = (index = 0) => {
  // 不顯示 tab (tab > 1) => 標籤名稱-tab順序-商品順序
  if (!isEmptyValue(tabData.value) && !isEmptyValue(productData.value)) return `${productData.value}-${currentTab.value + 1}-${index + 1}`;

  // 顯示 tab (tab <= 1) => 標籤名稱-商品順序
  if (isEmptyValue(tabData.value) && !isEmptyValue(productData.value)) return `${productData.value}-${index + 1}`;

  return '';
};
</script>

<style scoped>
.products {
  display: flex;
  gap: 8px;

  :deep(.product-card) {
    width: 140px;
    height: auto;
    flex-shrink: 0;
  }
  :deep(.card-image) {
    width: 140px;
    height: 140px;
  }
  :deep(.product-card-title) {
    @apply tw-text-[13px];
  }
  :deep(.product-card-description) {
    @apply tw-text-[14px];
  }
  :deep(.origin-dollar) {
    @apply tw-text-[12px];
  }
}
</style>
