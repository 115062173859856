import { filter, map, sortBy, prop, includes } from 'ramda';
import { isEmptyValue, emptyReplace } from '@/helper/data-process';
import { homePage2024BasicBannerFormatter } from '@/helper/ad/part/home-page-inner-formatter';
import { extraFieldEnum, productFormatterMaker } from '@/helper/product-formatter';
import { formatImagePath } from '@/helper/image-formatter';
// import { productFormatterBase } from '@/helper/product-formatter';
// import HomeAdProductDto from '@/dto/home-ad-product-dto';

// const homePageAdProductBaseFormat = (product) => new HomeAdProductDto(product, { formatter: productFormatterBase });

/** @const {function} sortBySort sort 排序但替換0為999，空或null時也替換為999 */
const sortBySort = (item) => {
  const sortValue = prop('sort', item);
  return sortValue === 0 || isEmptyValue(sortValue) ? 999 : sortValue;
};

/**
 * 【首頁廣告 fetch 之後整理資料用的】
 * Formatter 若要用 helper 取 `method` 名
 * 需按 constant/ad/homepage-ad-type.js
 * `format` + `mappingName` 取名
 * 舉例：formatHotTopicImage
 */

/**
 * B001 top_banner 版頭橫幅
 * @param ad
 * @return {{alt, link, image, imageMobile}}
 */
const formatTopBanner = (ad) => ({
  type: 'B001',
  alt: emptyReplace(ad.alt, ''),
  link: emptyReplace(ad.link, '#'),
  image: formatImagePath(emptyReplace(ad.web_img_url, '')),
  imageMobile: formatImagePath(emptyReplace(ad.mobile_img_url, '')),
});

/**
 * B002 logo
 * @param ad
 * @return {{alt, link, image}}
 */
const formatLogo = (ad) => ({
  type: 'B002',
  alt: emptyReplace(ad.alt, '誠品線上'),
  link: emptyReplace(ad.link, '/'),
  image: formatImagePath(emptyReplace(ad.img_url, '')),
});

/**
 * B003 search keywords 搜尋關鍵字
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatSearchKeywords = (ad) => ({
  type: 'B003',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.keyword, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

/**
 * B004 header_small_banner 全站小B
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatHeaderSmallBanner = (ad) => ({
  type: 'B004',
  image: formatImagePath(emptyReplace(ad.img_url, '')),
  alt: emptyReplace(ad.alt, ''),
  link: emptyReplace(ad.link, '#'),
});

/**
 * B005 big_slide_tabs 右側主題活動
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatBigSlideTabs = (ad) => ({
  type: 'B005',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.title, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

/**
 * B006 menu 分類選單
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatMenu = (ad) => ({
  type: 'B006',
  items: map((item) => {
    return {
      level1s: map(
        (l1) => ({
          title: emptyReplace(l1.title, ''),
          link: emptyReplace(l1.link, '#'),
        }),
        item.content.level1s,
      ),
      level2s: sortBy(
        sortBySort,
        map(
          (l2) => ({
            title: emptyReplace(l2.title, ''),
            link: emptyReplace(l2.link, '#'),
            image: formatImagePath(emptyReplace(l2.img_url, '')),
            sort: emptyReplace(l2.sort, 999),
            level3s: sortBy(
              sortBySort,
              map(
                (l3) => ({
                  title: emptyReplace(l3.title, ''),
                  link: emptyReplace(l3.link, '#'),
                  sort: emptyReplace(l3.sort, 999),
                }),
                l2.level3s || [],
              ),
            ),
          }),
          item.content.level2s || [],
        ),
      ),
      brands: sortBy(
        sortBySort,
        map(
          (brand) => ({
            title: emptyReplace(brand.title, ''),
            link: emptyReplace(brand.link, '#'),
            image: formatImagePath(emptyReplace(brand.img_url, '')),
            sort: emptyReplace(brand.sort, 999),
          }),
          item.content.brands,
        ),
      ),
      banners: sortBy(
        sortBySort,
        map(
          (banner) => ({
            title: emptyReplace(banner.title, ''),
            link: emptyReplace(banner.link, '#'),
            image: formatImagePath(emptyReplace(banner.img_url, '')),
            sort: emptyReplace(banner.sort, 999),
          }),
          item.content.banners,
        ),
      ),
    };
  }, emptyReplace(ad, [])),
});

const formatBigSlide = (ad) => ({
  type: 'B007',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        alt: emptyReplace(item?.banner?.alt, ''),
        link: emptyReplace(item?.banner?.link, '#'),
        image: formatImagePath(emptyReplace(item?.banner?.img_url, '')),
        products: map(
          (product) => ({
            id: emptyReplace(product.eslite_sn, 0),
            link: emptyReplace(product.link, '#'),
            title: emptyReplace(product.title, ''),
            name: emptyReplace(product.product_name, ''),
          }),
          emptyReplace(item.products, []),
        ),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

/**
 * B008 攻略廣告區塊
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const mapKeysByOrder = ['upper_left_x', 'upper_left_y', 'lower_right_x', 'lower_right_y'];
const formatHtmlMapBanner = (ad) => ({
  type: 'B008',
  web: {
    imageUrl: formatImagePath(ad.web.img_url),
    items: map((item) => {
      const coords = item?.coords
        ? mapKeysByOrder.reduce((result, key) => {
            result.push(item.coords[key]);
            return result;
          }, [])
        : [];

      return {
        link: emptyReplace(item?.link, '#'),
        coords,
      };
    }, emptyReplace(ad.web.content, [])),
  },
  mobile: {
    imageUrl: formatImagePath(ad.mobile.img_url),
    items: map((item) => {
      const coords = item?.coords
        ? mapKeysByOrder.reduce((result, key) => {
            result.push(item.coords[key]);
            return result;
          }, [])
        : [];
      return {
        link: emptyReplace(item?.link, '#'),
        coords,
      };
    }, emptyReplace(ad.mobile.content, [])),
  },
});

/**
 * B009 攻略廣告區塊
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatSmallBannerSlide = (ad) => ({
  type: 'B009',
  alt: emptyReplace(ad.alt, ''),
  color: emptyReplace(ad.background_color, '#FFFFFF'),
  image: formatImagePath(emptyReplace(ad.web_img_url, '')),
  imageMobile: formatImagePath(emptyReplace(ad.mobile_img_url, '')),
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        alt: emptyReplace(item?.alt, ''),
        link: emptyReplace(item?.link, '#'),
        image: formatImagePath(emptyReplace(item?.img_url, '')),
      }),
      emptyReplace(ad.cards, []),
    ),
  ),
});

/**
 * B010 product_card_slide 超划算搶購
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatProductCardSlide = (ad) => ({
  type: 'B010',
  items: [
    {
      products: sortBy(
        sortBySort,
        map(
          (product) => ({
            esliteSN: product.eslite_sn,
            title: emptyReplace(product.title, ''),
            name: emptyReplace(product.product_name, ''),
            link: emptyReplace(product.link, ''),
          }),
          emptyReplace(ad, []),
        ),
      ),
    },
  ],
});

/**
 * B011 product_card_slides 新書焦點
 * @param ad
 * @return {{page, type, blank, link, image, alt}}
 */
const formatProductCardSlides = (ad) => ({
  type: 'B011',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        title: emptyReplace(item.tab_title, ''),
        products: sortBy(
          sortBySort,
          map(
            (product) => ({
              esliteSN: product.eslite_sn,
              title: emptyReplace(product.title, ''),
              name: emptyReplace(product.product_name, ''),
              link: emptyReplace(product.link, ''),
              sort: emptyReplace(product.sort, 999),
            }),
            item.products || [],
          ),
        ),
      }),
      emptyReplace(
        filter((x) => !isEmptyValue(x.tab_title) && !isEmptyValue(x.products), ad),
        [],
      ),
    ),
  ),
});

// B012 membersOnly
const formatMembersOnly = (ad) => ({
  type: 'B012',
  items: sortBy(
    sortBySort,
    map((item) => ({ ...homePage2024BasicBannerFormatter(item) }), emptyReplace(ad, [])),
  ),
});
// B013 slideAndFourBanner
const formatSlideAndFourBanner = (ad = {}) => ({
  type: 'B013',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.alt, ''),
        image: formatImagePath(emptyReplace(item.img_url, '')),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad?.cards, []),
    ),
  ),
  banner: {
    alt: emptyReplace(ad?.long_banner.alt, ''),
    image: formatImagePath(emptyReplace(ad?.long_banner.web_img_url, '')),
    imageMobile: formatImagePath(emptyReplace(ad?.long_banner.mobile_img_url, '')),
    link: emptyReplace(ad?.long_banner.link, '#'),
  },
});

/**
 * B014 hotKeywords
 * @param {object} ad content
 * @returns
 */
const formatHotKeywords = (ad) => ({
  type: 'B014',
  alt: emptyReplace(ad.alt, ''),
  image: formatImagePath(emptyReplace(ad.img_url, '')),
  color: emptyReplace(ad.background_color, '#FFFFFF'),
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        title: emptyReplace(item.title, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad.keywords, []),
    ),
  ),
});

/**
 * B015 網路暢銷榜 (暫緩)
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatBestSellersOnline = (ad) => ({
  type: 'B015',
  items: Object.values(
    Object.keys(ad)
      .filter((key) => !includes(key, ['banner_type', 'page', 'blank']))
      .reduce((obj, key) => {
        const newKey = key.replace('L1_ID_', '');
        const extraFields = [extraFieldEnum.preorder, extraFieldEnum.author];
        const productFormatter = productFormatterMaker({ extraFields });
        const products = map(productFormatter, emptyReplace(ad[key], []));

        if (products.length > 0) obj[newKey] = { products, title: newKey };
        return obj;
      }, {}),
  ),
});

// B016 主題企劃
const formatNewMainTopicProject = (ad) => ({
  type: 'B016',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.alt, ''),
        image: formatImagePath(emptyReplace(item.img_url, '')),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

// B017 3小直方圖
const formatThreeBannerGroup = (ad) => ({
  type: 'B017',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.alt, ''),
        image: formatImagePath(emptyReplace(item.img_url, '')),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad.cards, []),
    ),
  ),
});

// B018 限定商品
const formatLimitedEditionGroup = (ad) => ({
  type: 'B018',
  items: [
    {
      products: sortBy(
        sortBySort,
        map(
          (product) => ({
            esliteSN: product.eslite_sn,
            title: emptyReplace(product.title, ''),
            name: emptyReplace(product.product_name, ''),
            link: emptyReplace(product.link, ''),
            sort: emptyReplace(product.sort, 999),
          }),
          emptyReplace(ad.products, []),
        ),
      ),
    },
  ],
});

// B019 獨家品牌
const formatExclusiveBrandGroup = (ad) => ({
  type: 'B019',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        image: formatImagePath(emptyReplace(item.img_url, '')),
        title: emptyReplace(item.title, ''),
        link: emptyReplace(item.link, ''),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad.brands, []),
    ),
  ),
});

// B020 強檔推薦
const formatStrongRecommendation = (ad) => ({
  type: 'B020',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        name: emptyReplace(item.tab_name, ''),
        sort: emptyReplace(item.sort, 999),
        alt: emptyReplace(item.alt, ''),
        color: emptyReplace(item.background_color, '#FFFFFF'),
        link: emptyReplace(item.link, ''),
        image: formatImagePath(emptyReplace(item.img_url, '')),
        products: map(
          (product) => ({
            esliteSN: product.eslite_sn,
            title: emptyReplace(product.title, ''),
            name: emptyReplace(product.product_name, ''),
            link: emptyReplace(product.link, ''),
            sort: emptyReplace(product.sort, 999),
          }),
          item.products || [],
        ),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

// B021 銀行/行動支付優惠總覽
const formatBankCardSlide = (ad) => ({
  type: 'B021',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        image: formatImagePath(emptyReplace(item.img_url, '')),
        alt: emptyReplace(item.alt, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

// B022 注目品牌 (2大6小)
const formatStrikingBrands = (ad) => ({
  type: 'B022',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        banner: {
          alt: emptyReplace(item?.banner.alt, ''),
          image: formatImagePath(emptyReplace(item?.banner.img_url, '')),
          link: emptyReplace(item?.banner.link, '#'),
        },
        products: sortBy(
          sortBySort,
          map(
            (product) => ({
              esliteSN: emptyReplace(product.eslite_sn, 0),
              title: emptyReplace(product.title, ''),
              name: emptyReplace(product.product_name, ''),
              link: emptyReplace(product.link, '#'),
              sort: emptyReplace(product.sort, 999),
            }),
            emptyReplace(item.products, []),
          ),
        ),
      }),
      emptyReplace(ad, []),
    ),
  ),
});
// B023 品牌列表
const formatBrandList = (ad) => ({
  type: 'B023',
  link: emptyReplace(ad.watch_more_link, ''),
  items: sortBy(
    sortBySort,
    map(
      (brand) => ({
        alt: emptyReplace(brand.alt, ''),
        image: formatImagePath(emptyReplace(brand.img_url, '')),
        link: emptyReplace(brand.link, '#'),
        sort: emptyReplace(brand.sort, 999),
      }),
      emptyReplace(ad.brands, []),
    ),
  ),
});

// B024 話題選品 (頁籤式1大6小品)
const formatTopicSelectionTabs = (ad) => ({
  type: 'B024',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        title: emptyReplace(item.tab_title, ''),
        main: {
          esliteSN: emptyReplace(item?.main_product?.eslite_sn, ''),
          message1: emptyReplace(item?.main_product?.title1, ''),
          message2: emptyReplace(item?.main_product?.title2, ''),
          alt: emptyReplace(item?.main_product?.product_name, ''), // alt
          title: emptyReplace(item?.main_product?.product_name, ''), // 商品名稱
          subTitle: emptyReplace(item?.main_product?.product_description, ''), // 長文內文 desc
          image: formatImagePath(emptyReplace(item?.main_product?.img_url, '')),
          link: emptyReplace(item?.main_product?.link, '#'),
        },
        products: sortBy(
          sortBySort,
          map(
            (product) => ({
              esliteSN: emptyReplace(product.eslite_sn, ''),
              title: emptyReplace(product.title, ''),
              name: emptyReplace(product.product_name, ''),
              link: emptyReplace(product.link, '#'),
              sort: emptyReplace(product.sort, 999),
            }),
            emptyReplace(item.sub_products, []),
          ),
          emptyReplace(item.sub_products, []),
        ),
      }),
      emptyReplace(
        filter((x) => !isEmptyValue(x.tab_title) && !isEmptyValue(x.sub_products), ad),
        [],
      ),
    ),
  ),
});

// B025 橫幅腰帶
// 同等 formatTopBanner
const formatWideBanner = (ad) => ({
  type: 'B025',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.alt, ''),
        link: emptyReplace(item.link, '#'),
        image: formatImagePath(emptyReplace(item.web_img_url, '')),
        imageMobile: formatImagePath(emptyReplace(item.mobile_img_url, '')),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

// B026 分類樓層
const formatNewThreadGroup = (ad) => ({
  type: 'B026',
  items: map((row) => {
    return {
      name: emptyReplace(row.group_name, ''),
      items: map((item) => {
        return {
          name: emptyReplace(item.name, ''),
          nameType: emptyReplace(item.name_type, ''),
          link: emptyReplace(item.watch_more_link, ''),
          sort: emptyReplace(item.sort, 999),
          banner: {
            alt: emptyReplace(item.banner.alt, ''),
            link: emptyReplace(item.banner.link, '#'),
            image: formatImagePath(emptyReplace(item.banner.img_url, '')),
          },
          products: sortBy(
            sortBySort,
            map(
              (product) => ({
                esliteSN: product.eslite_sn,
                title: emptyReplace(product.title, ''),
                name: emptyReplace(product.product_name, ''),
                link: emptyReplace(product.link, ''),
              }),
              emptyReplace(item.products, []),
            ),
          ),
        };
      }, emptyReplace(row.content, [])),
    };
  }, emptyReplace(ad, [])),
});

/**
 * A001
 * @param ad
 * @return {{alt, link, image, imageMobile}}
 */
const formatEightLinks = (ad) => ({
  type: 'A001',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        alt: emptyReplace(item?.alt, ''),
        link: emptyReplace(item?.link, '#'),
        image: formatImagePath(emptyReplace(item?.img_url, '')),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

/**
 * A002
 * @param ad
 * @return {{alt, link, image, imageMobile}}
 */
const formatCouponBanner = (ad) => ({
  type: 'A002',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        alt: emptyReplace(item.alt, ''),
        link: emptyReplace(item.link, '#'),
        image: formatImagePath(emptyReplace(item.img_url, '')),
        sort: emptyReplace(item.sort, 999),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

/**
 * A004
 * @param ad
 * @return {{alt, link, image, imageMobile}}
 */
const formatTenLinks = (ad) => ({
  type: 'A004',
  items: sortBy(
    sortBySort,
    map(
      (item) => ({
        sort: emptyReplace(item.sort, 999),
        alt: emptyReplace(item?.alt, ''),
        link: emptyReplace(item?.link, '#'),
        image: formatImagePath(emptyReplace(item?.img_url, '')),
      }),
      emptyReplace(ad, []),
    ),
  ),
});

export default {
  formatTopBanner,
  formatLogo,
  formatSearchKeywords,
  formatHeaderSmallBanner,
  formatBigSlideTabs,
  formatMenu,
  formatBigSlide,
  formatHtmlMapBanner,
  formatSmallBannerSlide,
  formatProductCardSlide,
  formatProductCardSlides,
  formatMembersOnly,
  formatSlideAndFourBanner,
  formatHotKeywords,
  formatBestSellersOnline,
  formatNewMainTopicProject,
  formatThreeBannerGroup,
  formatLimitedEditionGroup,
  formatExclusiveBrandGroup,
  formatStrongRecommendation,
  formatBankCardSlide,
  formatStrikingBrands,
  formatBrandList,
  formatTopicSelectionTabs,
  formatWideBanner,
  formatNewThreadGroup,
  formatEightLinks, // A001
  formatCouponBanner, // A002
  formatTenLinks, // A004
};
