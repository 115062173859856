<template>
  <component :is="componentName" />
</template>

<script setup>
import { computed, inject } from 'vue';
import EsliteHeader from '@/components/layout/pages-header-2024-desktop.vue';
import EsliteHeaderMobile from '@/components/layout/header-2024mobile.vue';

const $screen = inject('$screen');
const componentName = computed(() => ($screen.isMobileSize ? EsliteHeaderMobile : EsliteHeader));
</script>

<script>
export default { name: 'EsliteHeaderContainer' };
</script>
