import { isEmptyValue, getJSON, checkType } from '@/helper/data-process';

export const setDisplayUrl = (url) => window.history.pushState(null, null, url);

/**
 * 歷史紀錄
 */

/** @const {string} useKey 使用的 key 名稱 */
const useKey = `historyLogs`;

// 排除的頁面：搜尋, 貨到通知, 商品收藏
export const ignorePages = ['search', 'member-notify', 'member-wish', 'category-1-id', 'category-2-id'];

/**
 * 設定紀錄
 * 紀錄最大: 5 筆
 * 紀錄的最後一筆與目前頁面相同時跳過不紀錄
 * 若與上一筆紀錄為相同位置只是頁數參數不同時，舊紀錄的座標歸零
 * @param {*} data
 * @returns
 */
export const setHistory = (data = false) => {
  // 空資料, 沒有 path, y軸不為數字時跳出
  if (isEmptyValue(data?.path) || typeof data?.top !== 'number') return false;

  // 移除不需要的大資料
  delete data.matched;
  // 避免 error: Converting circular structure to JSON
  delete data.redirectedFrom;

  // 取得資訊
  let historyLogs = getJSON(sessionStorage.getItem(useKey) || []) || [];
  if (checkType(historyLogs) === 'Object') historyLogs = [historyLogs];

  // 紀錄的最後一筆與目前頁面相同時(next, prev, reload)：不動作等畫面完成後自動清除
  if (historyLogs.length > 0 && historyLogs[historyLogs.length - 1].fullPath === data.fullPath) {
    historyLogs[historyLogs.length - 1].left = data.left;
    historyLogs[historyLogs.length - 1].top = data.top;
    sessionStorage.setItem(useKey, JSON.stringify(historyLogs));
    return false;
  }
  // 若與上一筆紀錄為相同位置只是頁數參數不同時，舊紀錄的座標歸零
  if (historyLogs.length > 0 && historyLogs[historyLogs.length - 1].path === data.path) {
    historyLogs[historyLogs.length - 1].left = 0;
    historyLogs[historyLogs.length - 1].top = 0;
  }

  // 與裡面某一筆記錄完全相同時，移除舊紀錄
  const getOldDataIndex = historyLogs.findIndex((x) => x.fullPath === data.fullPath);
  if (getOldDataIndex !== -1) {
    historyLogs.splice(getOldDataIndex, 1);
  }

  // 移除舊的：同 component 但是細項不同（館別不同館）
  // if (historyLogs.find((x) => x.name === data.name) !== undefined) {
  //   const index = historyLogs.findIndex((x) => x.name === data.name);
  //   if (index > -1) historyLogs.splice(index, 1);
  // }
  historyLogs.push(data);

  // 超過 5 筆時移除第一筆
  if (historyLogs.length > 5) historyLogs.shift();

  historyLogs = historyLogs.filter((item) => !isEmptyValue(item)).map((item, index) => ({ ...item, sort: index + 1 }));
  sessionStorage.setItem(useKey, JSON.stringify(historyLogs));
  return true;

  // return false;
};

/**
 * 取得該頁記錄
 * @param {*} data 搜尋頁面
 * @returns {false|object} 查無資料或取得該筆 router 資訊
 */
export const getHistory = (data) => {
  // 取得資訊
  let historyLogs = getJSON(sessionStorage.getItem(useKey) || []) || [];
  if (checkType(historyLogs) === 'Object') historyLogs = [historyLogs];

  if (historyLogs.length <= 0) return false;

  // 若帶入值為字串時比對 path
  if (checkType(data) === 'String') {
    const strFindResult = historyLogs.find((x) => x.path === data);
    if (strFindResult !== undefined) return strFindResult;
  }

  // 取 path 相同的那一筆
  // find 改成 filter，因為 historyLogs 裡可能會不只一個相同的 path，取最後一筆為最新有紀錄 y 的
  const findData = historyLogs.filter((x) => x.path === data.path);
  if (typeof data?.path !== 'undefined' && !isEmptyValue(findData)) return findData[findData.length - 1];

  // 預設給第一筆 （不帶來源或 path 對不上）
  // return historyLogs.find((x) => x.sort === 1);
  return false;
};

/**
 * 移除紀錄
 * @param {*} data
 * @param {boolean} deleteAll 移除全部紀錄
 * @returns {boolean}
 */
export const deleteHistory = (data, deleteAll = false) => {
  // 取得資訊
  let historyLogs = getJSON(sessionStorage.getItem(useKey) || []) || [];
  if (historyLogs.length <= 0) return false;

  // 移除該筆
  if (!deleteAll && historyLogs.find((x) => x.name === data.name) !== undefined) {
    const index = historyLogs.findIndex((x) => x.name === data.name);
    if (index > -1) historyLogs.splice(index, 1);
  }

  // 移除全部
  if (deleteAll) historyLogs = historyLogs.filter((x) => x.name !== data.name);

  historyLogs = historyLogs.map((item, index) => ({ ...item, sort: index + 1 }));
  sessionStorage.setItem(useKey, JSON.stringify(historyLogs));
  return true;
};
