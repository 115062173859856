<template>
  <BannerBoxMobile :show-banner="true" :title="title" class="carousel">
    <template #body>
      <div class="tw-w-full">
        <!-- B016 -->
        <div class="tw-w-full">
          <banner-swiper v-if="showBanner" :items="mainTopicProjectBannerGroup" id-data="home-topic-b" />
          <div v-else class="loading logo-loading tw-w-full tw-pb-[44%]">&nbsp;</div>
        </div>
        <!-- B017 -->
        <div v-if="threeBannerGroup.length > 0" class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-2 tw-pt-4">
          <div v-for="(item, index) in threeBannerGroup" :key="index" class="banner-column">
            <RouterLinkUsage v-if="!$isEmpty(item.link)" :id="`home-topic-sb-${index + 1}`" :link="item.link" :title="item.alt">
              <img v-lazy="item.image" v-default-image="defaultImage" :src="item.image" :alt="item.alt" />
            </RouterLinkUsage>
          </div>
        </div>
        <div v-else class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-2 tw-pt-4 B017">
          <div class="loading logo-loading tw-pb-[130%]">&nbsp;</div>
          <div class="loading logo-loading tw-pb-[130%]">&nbsp;</div>
          <div class="loading logo-loading tw-pb-[130%]">&nbsp;</div>
        </div>
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import BannerSwiper from '@/components/utility/swiper/banner-swiper.vue';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { productDefaultWhiteImg } from '@/constant/images-path';

const defaultImage = ref(productDefaultWhiteImg);

const props = defineProps({
  title: { type: String, default: '' },
});
const { title } = toRefs(props);

const homeAdStore = useHomeAd2024Store();
const { getNewMainTopicProject, getThreeBannerGroup } = storeToRefs(homeAdStore);

/** B016 主題企劃 */
const mainTopicProjectBannerGroup = computed(() => (getNewMainTopicProject.value?.items || []).slice(0, 10));
const showBanner = computed(() => mainTopicProjectBannerGroup.value.length > 0 || false);
/** B017 直式3banner */
const threeBannerGroup = computed(() => (getThreeBannerGroup.value?.items || []).slice(0, 3));
const allEmpty = computed(() => showBanner.value === false && threeBannerGroup.value.length <= 0);
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.banner-column {
  @apply tw-col-span-1;

  img {
    @apply tw-w-full tw-rounded-lg;
  }
}

.B017 .loading {
  background-size: 80%;
}
</style>
