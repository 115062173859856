<template>
  <div v-if="hasItems" class="banner-box" :style="{ backgroundColor: getSmallBannerSlide.color || '#FFFFFF' }">
    <div class="title"><img :src="getSmallBannerSlide.image" :alt="getSmallBannerSlide.alt" /></div>
    <div v-if="items.length > 0" class="slide-box">
      <swiper ref="swiperElem" :options="swiperOptions" @swiper="setSwiper">
        <swiper-slide v-for="(item, index) in items" :key="index" class="swiper-slide banner-row">
          <router-link-usage :id="`home-strategy-${index + 1}`" :link="item.link" :title="item.alt" class="banner"><img v-lazy="item.image" :alt="item.alt" /></router-link-usage>
        </swiper-slide>
      </swiper>
      <div v-show="useSwiper" ref="PrevEl" class="swiper-button-prev" @click="prev"></div>
      <div v-show="useSwiper" ref="NextEl" class="swiper-button-next" @click="next"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { adjustArray } from '@/helper/grid';

const homeAdStore = useHomeAd2024Store();
const { getSmallBannerSlide } = storeToRefs(homeAdStore);

const items = computed(() => adjustArray(getSmallBannerSlide.value?.items || [], 7));
const hasItems = computed(() => items.value.length > 0);
const useSwiper = computed(() => items.value.length > 7);

const swiperOptions = {
  slidesPerView: 7,
  spaceBetween: 12,
  slidesPerGroup: 1,
  loop: true,
};

const swiperElem = ref(null);
const swiperInstance = ref(null);
const NextEl = ref(null);
const PrevEl = ref(null);
const setSwiper = (swiper) => (swiperInstance.value = swiper);
const next = () => swiperInstance.value.slideNext();
const prev = () => swiperInstance.value.slidePrev();
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.banner-box {
  @apply tw-mb-16 tw-overflow-hidden;
}

.title {
  @apply tw-h-[80px] tw-mb-4;
}

.swiper {
  width: calc(100% - 40px);
}

.slide-box {
  @apply tw-mx-0 tw-mb-8 tw-relative;
}

.swiper-slide {
  @apply tw-w-[168px];
}

.banner {
  @apply tw-w-[168px] tw-h-[248px] tw-flex;
  img {
    @apply tw-rounded-lg;
  }
}

.banner-row {
  @apply tw-w-full tw-relative;
}

.slide-box:hover {
  .swiper-button-prev,
  .swiper-button-next {
    @apply tw-flex;
  }
}
</style>
