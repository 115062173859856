import { mergeAll, map, zipObj, filter } from 'ramda';

/**
 * @const {array} homePageAD
 * 順序:
 * @face-1
 * focus_point [1. 注目焦點]
 * big_b_new [1. 大 banner]
 * home_page_recommend_of_the_day [1. 今日最推]
 * home_page_recommend_of_the_day_by_tabs [1. 今日最推v2]
 * hot_topic_image [1. 熱門話題]
 * hot_topic_text [1. 熱門話題]
 * hot_search [1. 流行熱搜]
 * member_little_b [1. 會員小 B]
 * @face-2
 * discount_big_b [2. 好康優惠大 B]
 * discount_little_b [2. 好康優惠小 B]
 * discount_area [2. 好康優惠專區]
 * home_page_horizontal_paint [2. 橫幅]
 * @face-3
 * home_page_online_leaderboard [3. g1 網路暢銷榜]
 * eslite_exclusive [3. g1 誠品獨家]
 * book_of_eslite_choice [3. g1 誠品選書]
 * music_of_eslite_choice [3. g1 誠品選樂]
 *
 * home_page_main_topic_project [3. g2 主題企劃]
 * brand_week [3. g2 品牌週]
 * @face-4
 * thread_group [4. 線別]
 */
export const homePageAD = [
  { description: '大Banner', code: 'big_b_new', key: 'bigBanner', mappingName: 'BigBanner', mod: '' },
  { description: '注目焦點', code: 'focus_point', key: 'focusPoint', mappingName: 'FocusPoint', mod: '' },
  { description: '今日最推', code: 'home_page_recommend_of_the_day_by_tabs', key: 'groupRecommend', mappingName: 'GroupRecommend', mod: '' },
  { description: '熱門話題(文)', code: 'hot_topic_text', key: 'hotTopicText', mappingName: 'HotTopicText', mod: '' },
  { description: '熱門話題(圖)', code: 'hot_topic_image', key: 'hotTopicImage', mappingName: 'HotTopicImage', mod: '' },
  { description: '流行熱搜', code: 'hot_search', key: 'hotSearch', mappingName: 'HotSearch', mod: '' },
  { description: '會員小B', code: 'member_little_b', key: 'memberBanner', mappingName: 'MemberBanner', mod: '' },
  { description: '好康優惠大B', code: 'discount_big_b', key: 'discountBannerLarge', mappingName: 'DiscountBannerLarge', mod: '' },
  { description: '好康優惠小B', code: 'discount_little_b', key: 'discountBannerSmall', mappingName: 'DiscountBannerSmall', mod: '' },
  { description: '好康優惠專區', code: 'discount_area', key: 'discountArea', mappingName: 'DiscountArea', mod: '' },
  { description: '橫幅', code: 'home_page_horizontal_paint', key: 'horizontalPaint', mappingName: 'HorizontalPaint', mod: '' },
  { description: '主題企劃', code: 'home_page_main_topic_project', key: 'mainTopicProject', mappingName: 'MainTopicProject', mod: '' },
  { description: '品牌週', code: 'brand_week', key: 'weeklyBrands', mappingName: 'WeeklyBrands', mod: '' },
  { description: '品牌列表', code: 'brand_listing', key: 'brandListing', mappingName: 'BrandListing', mod: '' },
  { description: '線別', code: 'thread_group', key: 'threadGroups', mappingName: 'ThreadGroups', mod: '' },
  { description: '誠品獨家', code: 'eslite_exclusive', key: 'esliteExclusive', mappingName: 'EsliteExclusive', mod: '' },
  { description: '誠品選書', code: 'book_of_eslite_choice', key: 'esliteBook', mappingName: 'EsliteBook', mod: '' },
  { description: '誠品選樂', code: 'music_of_eslite_choice', key: 'esliteMusic', mappingName: 'EsliteMusic', mod: '' },
  { description: '網路暢銷榜', code: 'home_page_online_leaderboard', key: 'onlineLeaderboard', mappingName: 'OnlineLeaderboard', mod: '' },

  /**
   * 2024 首頁改版 Banner
   */
  { description: 'B001 版頭橫幅', code: 'B001', key: 'topBanner', mappingName: 'TopBanner', mod: 'index2024' },
  { description: 'B002 搜尋關鍵字', code: 'B002', key: 'logo', mappingName: 'Logo', mod: 'index2024' },
  { description: 'B003 搜尋關鍵字', code: 'B003', key: 'searchKeywords', mappingName: 'SearchKeywords', mod: 'index2024' },
  { description: 'B004 右側主題活動', code: 'B004', key: 'headerSmallBanner', mappingName: 'HeaderSmallBanner', mod: 'index2024' },
  { description: 'B005 右側主題活動', code: 'B005', key: 'bigSlideTabs', mappingName: 'BigSlideTabs', mod: 'index2024' },
  { description: 'B006 分類選單', code: 'B006', key: 'menu', mappingName: 'Menu', mod: 'index2024' },
  { description: 'B007 大看板+3品', code: 'B007', key: 'bigSlide', mappingName: 'BigSlide', mod: 'index2024' },
  // HTML banner
  { description: 'B008 HTML區塊', code: 'B008', key: 'htmlMapBanner', mappingName: 'HtmlMapBanner', mod: 'index2024' },
  { description: 'B009 攻略廣告區塊', code: 'B009', key: 'smallBannerSlide', mappingName: 'SmallBannerSlide', mod: 'index2024' },

  { description: 'B010 超划算搶購', code: 'B010', key: 'productCardSlide', mappingName: 'ProductCardSlide', mod: 'index2024' },
  { description: 'B011 新書焦點', code: 'B011', key: 'productCardSlides', mappingName: 'ProductCardSlides', mod: 'index2024' },
  { description: 'B012 會員獨享', code: 'B012', key: 'membersOnly', mappingName: 'MembersOnly', mod: 'index2024' },
  { description: 'B013 3小1橫幅', code: 'B013', key: 'slideAndFourBanner', mappingName: 'SlideAndFourBanner', mod: 'index2024' },
  { description: 'B014 熱門關鍵字', code: 'B014', key: 'hotKeywords', mappingName: 'HotKeywords', mod: 'index2024' },

  { description: 'B015 網路暢榜', code: 'B015', key: 'bestSellersOnline', mappingName: 'BestSellersOnline', mod: 'index2024' },
  { description: 'B016 主題企劃', code: 'B016', key: 'newMainTopicProject', mappingName: 'NewMainTopicProject', mod: 'index2024' },
  { description: 'B017 3小直方圖', code: 'B017', key: 'threeBannerGroup', mappingName: 'ThreeBannerGroup', mod: 'index2024' },
  { description: 'B018 限定商品', code: 'B018', key: 'limitedEditionGroup', mappingName: 'LimitedEditionGroup', mod: 'index2024' },
  { description: 'B019 獨家品牌', code: 'B019', key: 'exclusiveBrandGroup', mappingName: 'ExclusiveBrandGroup', mod: 'index2024' },
  { description: 'B020 強檔推薦', code: 'B020', key: 'strongRecommendation', mappingName: 'StrongRecommendation', mod: 'index2024' },
  { description: 'B021 銀行/行動支付優惠總覽', code: 'B021', key: 'bankCardSlide', mappingName: 'BankCardSlide', mod: 'index2024' },
  { description: 'B022 注目品牌', code: 'B022', key: 'strikingBrands', mappingName: 'StrikingBrands', mod: 'index2024' },
  { description: 'B023 品牌列表', code: 'B023', key: 'brandList', mappingName: 'BrandList', mod: 'index2024' },
  { description: 'B024 話題選品', code: 'B024', key: 'topicSelectionTabs', mappingName: 'TopicSelectionTabs', mod: 'index2024' },
  { description: 'B025 橫幅腰帶', code: 'B025', key: 'wideBanner', mappingName: 'WideBanner', mod: 'index2024' },
  { description: 'B026 分類樓層', code: 'B026', key: 'newThreadGroup', mappingName: 'NewThreadGroup', mod: 'index2024' },

  { description: 'A001 8小幅', code: 'A001', key: 'eightLinks', mappingName: 'EightLinks', mod: 'index2024' },
  { description: 'A002 領券橫幅', code: 'A002', key: 'couponBanner', mappingName: 'CouponBanner', mod: 'index2024' },
  { description: 'A004 分類選單 10小幅', code: 'A004', key: 'tenLinks', mappingName: 'TenLinks', mod: 'index2024' },
];

/**
 * 首頁廣告「類型」
 * {object} homePageADTypeKey { key: code } 組合
 * @sample bigBanner: 'big_b_new'
 * =============================================
 * @property bigBanner
 * @property focusPoint
 * @property groupRecommend
 * @property hotTopicText
 * @property hotTopicImage
 * @property hotSearch
 * @property memberBanner
 * @property discountBannerLarge
 * @property discountBannerSmall
 * @property discountArea
 * @property horizontalPaint
 * @property mainTopicProject
 * @property weeklyBrands
 * @property threadBannerSmall
 * @property threadProducts
 * @property threadKeywords
 * @property esliteBook
 * @property esliteExclusive
 * @property esliteMusic
 * @property onlineLeaderboard
 * @property brandListing
 * */
export const homePageADTypeKey = (() => mergeAll(map((x) => zipObj([x.key], [x.key]), homePageAD)))();

/**
 * 首頁廣告「類型」
 * {object} homePageADTypeEnum { key: code } 組合
 * @sample bigBanner: 'big_b_new'
 * =============================================
 * @property bigBanner
 * @property focusPoint
 * @property groupRecommend
 * @property hotTopicText
 * @property hotTopicImage
 * @property hotSearch
 * @property memberBanner
 * @property discountBannerLarge
 * @property discountBannerSmall
 * @property discountArea
 * @property horizontalPaint
 * @property mainTopicProject
 * @property weeklyBrands
 * @property threadBannerSmall
 * @property threadProducts
 * @property threadKeywords
 * */
export const homePageADTypeEnum = (() => mergeAll(map((x) => zipObj([x.key], [x.code]), homePageAD)))();

/**
 * 首頁廣告「Mapping」
 *   用來對應 method name
 * {object} homePageADTypeEnum { code: mappingName } 組合
 * @sample BigBanner: 'big_b_new'
 * =============================================
 * @property BigBanner
 * @property FocusPoint
 * @property GroupRecommend
 * @property HotTopicText
 * @property HotTopicImage
 * @property HotSearch
 * @property MemberBanner
 * @property DiscountBannerLarge
 * @property DiscountBannerSmall
 * @property DiscountArea
 * @property HorizontalPaint
 * @property MainTopicProject
 * @property WeeklyBrands
 * @property ThreadBannerSmall
 * @property ThreadProducts
 * @property ThreadKeywords
 * */
export const homePageADMappingEnum = (() => mergeAll(map((x) => zipObj([x.code], [x.mappingName]), homePageAD)))();

/**
 * @const {object} homePageADMappingEnumWithNewIndex
 * { B001: 'TopBanner', B003: 'SearchKeywords', B004: 'HeaderSmallBanner', B005: 'BigSlideTabs', B006: 'Menu', B007: 'BigSlide', B008: 'HtmlMapBanner', B009: 'SmallBannerSlide', B010: 'ProductCardSlide', B011: 'ProductCardSlides', B012: 'MembersOnly', B013: 'SlideAndFourBanner', B014: 'HotKeywords', B015: 'BestSellersOnline', B016: 'NewMainTopicProject', B017: 'ThreeBannerGroup', B018: 'LimitedEditionGroup', B019: 'ExclusiveBrandGroup', B020: 'StrongRecommendation', B021: 'BankCardSlide', B022: 'StrikingBrands', B023: 'BrandList', B024: 'TopicSelectionTabs', B025: 'WideBanner', B026: 'ThreadGroup', A001: 'EightLinks', A002: 'CouponBanne' }
 */
export const homePageADMappingEnumWithNewIndex = mergeAll(
  map(
    (x) => zipObj([x.code], [x.mappingName]),
    filter((x) => x.mod === 'index2024', homePageAD),
  ),
);

/**
 * 首頁廣告「Mapping」
 *   用來對應 method name
 * {object} homePageADTypeEnum { code: key } 組合
 * @sample 'big_b_new': bigBanner
 * */
export const homePageADKeyEnum = (() => mergeAll(map((x) => zipObj([x.code], [x.key]), homePageAD)))();

export const esliteExclusiveEnum = {
  [homePageADTypeKey.esliteExclusive]: { key: homePageADTypeKey.esliteExclusive, hat: 'eslite_only', siteLink: '/eslite-only', wording: '誠品獨家' },
  [homePageADTypeKey.esliteBook]: { key: homePageADTypeKey.esliteBook, hat: 'eslite_book', siteLink: '/eslite-choice-books', wording: '誠品選書' },
  [homePageADTypeKey.esliteMusic]: { key: homePageADTypeKey.esliteMusic, hat: 'eslite_music', siteLink: '/eslite-choice-music', wording: '誠品選樂' },
};
