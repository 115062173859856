export const def = {
  strategy: import.meta.env.VITE_TYPE || 'local',
};

export const defKey = {
  strategy: 'auth.strategy',
  token: `auth._token.${def.strategy}`,
  refreshToken: `auth._refresh_token.${def.strategy}`,
  false: 'false',
  bearer: 'Bearer ',
};

export const authCookie = {
  registry: 'registration_token',
};
