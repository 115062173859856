import { map } from 'ramda';
import { adTypeEnum } from '@/constant/ad/ad-type';
import { emptyReplace } from '@/helper/data-process';
import { extraFieldEnum, productFormatterMaker } from '@/helper/product-formatter';
import { formatImagePath } from '@/helper/image-formatter';

export const homePageBasicFieldFormatter = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.home),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
});

/** @const {method} homePage2024BasicBannerFormatter 預設 banner 結構 */
export const homePage2024BasicBannerFormatter = (ad) => ({
  alt: emptyReplace(ad.alt, ''),
  title: emptyReplace(ad.title || ad.name, ''),
  image: formatImagePath(emptyReplace(ad.img_url, '')),
  link: emptyReplace(ad.link, '#'),
  sort: emptyReplace(ad.sort, 99),
});

/** @const {method} homePage2024BasicProductFormatter 預設 product 結構 */
export const homePage2024BasicProductFormatter = (ad) => ({
  id: ad.product_guid,
  esliteSN: ad.eslite_sn,
  title: emptyReplace(ad.title, ''),
  name: emptyReplace(ad.name, ''),
  image: emptyReplace(ad.image, ''),
  preorder: emptyReplace(ad.preorder, false),
  description: emptyReplace(ad.short_description || ad.description, ''),
  author: emptyReplace(ad.author, ''),
  restricted: emptyReplace(ad.restricted, false),
  link: emptyReplace(ad.product_link, '#'),
});

export const formatThreadBannerSmall = (ad) => ({
  blank: emptyReplace(ad.blank, false),
  items: map(
    (item) => ({
      alt: emptyReplace(item.alt, ''),
      link: emptyReplace(item.link, '#'),
      position: emptyReplace(item.sort, 99),
      image: emptyReplace(item.image, ''),
      title: emptyReplace(item.title_1, ''),
      subtitle: emptyReplace(item.title_2, ''),
    }),
    emptyReplace(ad.items, []),
  ),
});

export const formatThreadProducts = (ad) => {
  const productFormatter = productFormatterMaker({ extraFields: [extraFieldEnum.preorder] });
  return {
    blank: emptyReplace(ad.blank, false),
    products: map(productFormatter, emptyReplace(ad.products, [])),
  };
};

export const formatThreadKeywords = (ad) => ({
  blank: emptyReplace(ad.blank, false),
  keywords: map(
    (keyword) => ({
      link: emptyReplace(keyword.link, '#'),
      text: emptyReplace(keyword.text, ''),
    }),
    emptyReplace(Object.values(ad.keywords), []),
  ),
});
