<template>
  <BannerBox :show-banner="hasItems" :title="title" class="carousel">
    <template #subTitle>
      <ul v-if="formattedItems?.length > 1" class="tabs">
        <li
          v-for="(tab, index) in formattedItems"
          :id="tab.index !== undefined ? `home-hotcollections-tags-${tab.index + 1}` : ''"
          :key="index"
          :class="{ active: currentTab === tab.index, empty: !tab.title }"
          @click="selectTab(tab.index)"
        >
          {{ tab.title || '' }}
        </li>
      </ul>
    </template>
    <template #body>
      <div class="box">
        <div class="right tw-w-1/2"><card v-if="!isEmptyValue(main.esliteSN)" :id-data="`home-hotcollections-main-${currentTab + 1}`" :product="main" :mode-big="true" /></div>
        <div class="left tw-w-1/2"><card v-for="(product, index) in products" :key="index" :id-data="`home-hotcollections-product-${currentTab + 1}-${index + 1}`" :product="product" /></div>
      </div>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, watch } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import { isEmptyValue } from '@/helper/data-process';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
  items: { type: Array, default: () => [], required: true },
});

const { title, items } = toRefs(props);

const hasItems = computed(() => items.value.length > 0);

const currentTab = ref(0);
const first = ref(true);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const main = computed(() => items.value[currentTab.value]?.main || {});
const products = computed(() => items.value[currentTab.value]?.products?.slice(0, 6) || []);

const insertSpaces = (arr) =>
  arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.title, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.title, index }));
const formattedItems = computed(() => insertSpaces(items.value));

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN, items.value[currentTab.value]?.products || []);
  const mainId = main.value.esliteSN;
  const productIds = [...ids, mainId].filter((el) => el);
  if (productIds.length > 0) fetchPrice({ productIds });
};

const selectTab = (index, isClick = true) => {
  if (index === undefined || (currentTab.value === index && isClick === true)) return false;
  currentTab.value = index;
  getProductPrices();
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (nv === undefined || nv.length <= 0) return false;
  currentTab.value = first.value ? randomIfNotTest(nv.length - 1, 0) : 0;
  if (first.value === true) first.value = false;
  selectTab(currentTab.value, false);
  return true;
};

onMounted(() => {
  watch(items, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.tabs {
  @apply tw-list-none tw-flex tw-justify-around tw-p-0 tw-m-0;
}
.tabs li {
  @apply tw-p-2 tw-text-[18px] tw-leading-[22px] tw-font-medium tw-rounded tw-cursor-pointer;
  color: theme('colors.gray-500');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    @apply tw-cursor-default tw-leading-[18px];
    color: theme('colors.gray-200');
    &::before {
      @apply tw-text-[10px];
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
  &.more {
    @apply tw-text-[16px] tw-leading-[22px] tw-font-thin tw-rounded tw-pl-0 tw-cursor-pointer;
    a {
      color: theme('colors.gold-300');
    }
  }
}

.box {
  @apply tw-w-full tw-flex;
  .right {
    @apply tw-pr-[10px];
    :deep(img) {
      @apply tw-w-[628px] tw-h-[418px];
    }
  }
  .left {
    @apply tw-pl-[10px];
    @apply tw-grid tw-grid-cols-3 tw-gap-x-4 tw-gap-y-6;
  }
  a {
    @apply tw-flex;
  }
  img {
    @apply tw-w-[630px] tw-h-[420px];
  }
}
</style>
