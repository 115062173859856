<template>
  <footer class="lg:tw-block tw-pt-2" :class="{ bgw: $inApp }">
    <back-to-top relative-container-selector="footer"></back-to-top>
    <div v-if="!$inApp" class="tw-container tw-m-auto lg:tw-px-0 tw-overflow-hidden" data-test-id="footer">
      <div class="tw-row tw-justify-between tw-pt-2 tw-pb-6 tw-flex-col lg:tw-flex-row lg:tw-items-stretch">
        <div v-for="(items, index) in webMap" :key="`web-map-${index}`" class="footer-row tw-col-auto tw-mb-2 lg:tw-mb-0">
          <div class="tw-flex">
            <div class="tw-col-12 tw-flex tw-justify-between">
              <p>{{ items[0] }}</p>
              <div class="button">
                <p class="icon-fa-plus" @click="parentMsg"></p>
                <p class="icon-fa-minus" @click="parentMsg"></p>
              </div>
            </div>
          </div>
          <ul>
            <li v-for="item in items" :key="item.id">
              <a
                v-if="checkIsCustomerService(item.name)"
                :id="item.id"
                :href="item.link"
                target="_blank"
                rel="noreferrer"
                :aria-label="item.id || 'footer-item'"
                data-test-id="footer-contact-button"
                @click.prevent="alertCustomer"
                >{{ item.name }}</a
              >
              <a v-else :id="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id || 'footer-item'">{{ item.name }}</a>
            </li>
          </ul>
          <div class="line"></div>
          <div v-if="items[0] === '訂閱誠品線上電子報'" class="tw-flex tw-mt-2 lg:tw-mt-12">
            <div class="tw-col-12 tw-flex tw-justify-between lg:tw-flex-col tw-items-center lg:tw-items-stretch">
              <p class="mb-mb">{{ focus.name }}</p>
              <ul class="focus !tw-flex tw-justify-between tw-m-0">
                <li v-for="item in focus.icons" :key="`focus-icons-${item.id}`">
                  <a :id="item.id" :key="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id">
                    <img v-lazy="item.icon" :title="item.name" alt="" src="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tw-col-auto">
          <div class="line"></div>
          <div class="tw-flex">
            <!-- app-->
            <div class="tw-col-12 tw-flex tw-justify-between lg:tw-flex-col tw-items-center lg:tw-items-stretch">
              <p class="mb-mb">{{ app.name }}</p>
              <ul class="app !tw-flex lg:tw-flex-col tw-m-0 tw-mt-2 lg:tw-mt-1">
                <li v-for="item in app.icons" :key="item.id" class="tw-mb-2">
                  <a :id="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id">
                    <img v-lazy="item.icon" :title="item.name" alt="" src="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="line"></div>
          <div class="tw-row">
            <!--  資安防護-->
            <div class="tw-col-12 tw-flex tw-justify-between lg:tw-flex-col tw-mt-2 lg:tw-mt-6">
              <ul class="qrcode tw-flex tw-m-0 tw-mt-1">
                <li :key="qrCode.icons.id" class="tw-mb-2">
                  <a :id="qrCode.icons.id" :href="qrCode.icons.link" target="_blank" rel="noreferrer" :aria-label="qrCode.icons.name">
                    <div class="tw-row tw-items-center">
                      <div class="qrcode-icon tw-col-auto tw-hidden lg:tw-block tw-pr-0">
                        <img v-lazy="qrCode.icons.icon" :title="qrCode.icons.name" alt="" src="" />
                      </div>
                      <div class="qrcode-icon tw-col-auto lg:tw-mt-auto">
                        <span>{{ qrCode.icons.content.first }}<br class="tw-hidden lg:tw-block" />{{ qrCode.icons.content.last }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="line !tw-mb-4 lg:!tw-mb-0"></div>
        <div class="tw-col-12 copyright">
          <span>誠品線上eslite.com - powered by 誠品生活 / 誠品書店 / 誠品物流 | 誠品生活股份有限公司 (eslite Spectrum Corporation)</span>
          <br />
          <span>統一編號：27952966 | 台灣台北市信義區松德路204號B1 服務電話：0800-666-798／+886-2-8789-8921</span>
          <br />
          <span>本網站已依台灣網站內容分級規定處理｜建議使用瀏覽器版本：Google Chrome版本60以上 / Firefox版本48以上 / Safari 版本11以上</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useCustomerServiceStore } from '@/stores/customer-service';
import { focus, app, qrCode, webMap } from '@/constant/footer';
import BackToTop from '@/components/layout/elements/back-to-top.vue';

const components = {
  BackToTop,
};

const setup = () => {
  const customerServiceStore = useCustomerServiceStore();

  // methods
  const parentMsg = (event) => {
    event.target.parentNode.parentNode.parentNode.classList.toggle('active');
  };

  const checkIsCustomerService = (data) => data === '聯絡我們';

  const alertCustomer = () => customerServiceStore.setCustomerSwitch(true);

  return {
    // data
    focus,
    app,
    qrCode,
    webMap,
    // methods
    parentMsg,
    checkIsCustomerService,
    alertCustomer,
  };
};

export default {
  name: 'Footer',
  components,
  setup,
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  background-color: theme('colors.gray-80');

  .line {
    width: 100%;
  }
  .button {
    display: none;
  }
  p {
    color: theme('colors.gray-900');
    @include font17;
    margin-bottom: 0.4rem;
  }
  ul {
    li {
      a {
        text-decoration: none;
        color: theme('colors.gray-800');
        @include font15;
        line-height: 1.7rem;
      }
    }
  }
  .app {
    img {
      max-width: 140px;
      max-height: 41.5px;
    }
    li {
      margin-left: 0;
    }
  }
  .qrcode {
    img {
      max-width: 70px;
      max-height: 70px;
    }
  }
  .qrcode-icon {
    @include font13;
  }
  .focus {
    li {
      margin-right: 0px;
    }
    img {
      max-width: 48px;
      max-height: 48px;
    }
  }
  .copyright {
    @include fontSize(12);
    color: var(--brownish-grey);
    text-align: center;
    width: 100%;
  }
  @include mediaMax($grid-breakpoints-lg) {
    .line {
      border-bottom: solid 1px theme('colors.gray-800');
    }
    .mb-mb {
      margin-bottom: 0;
    }
    .button {
      cursor: pointer;
      display: block;
      P {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .footer-row {
      ul {
        display: none;
      }
      .active {
        .button {
          p {
            display: none;
          }
          p + P {
            display: block;
          }
        }
        & + ul {
          display: block;
        }
      }
    }
    ul {
      li {
        span {
          color: theme('colors.gray-800');
          @include font17;
        }
      }
    }
    .app {
      li {
        margin-left: 4px;
      }
      img {
        max-height: 35.6px;
      }
    }
    .focus {
      li {
        margin-right: 16px;
      }
      li:last-child {
        margin-right: 0;
      }
      img {
        max-width: 28px;
        max-height: 28px;
      }
    }
  }
  // 防止 320px 時，寬 overflow，使 header 跑版
  @include mediaMax($grid-breakpoints-sm) {
    .app {
      img {
        max-width: 35vw;
      }
    }
  }
}

footer.bgw {
  background-color: var(--white);
}
</style>
