import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useRetailSearchLogStore = defineStore('retailSearch', () => {
  const attributionToken = ref('');

  const getAttributionToken = computed(() => attributionToken.value);
  const setAttributionToken = (token) => {
    attributionToken.value = token.value;
  };
  return {
    getAttributionToken,
    setAttributionToken,
  };
});
