<template>
  <div class="virtual-shelf-page-control" data-test-id="virtual-shelf-page-control">
    <button class="btn-local ec-btn ec-btn-black tw-rounded-none" @click.prevent="back">
      <i class="icon icon-fa-chevron-left"></i>
    </button>
    <button class="btn-local ec-btn ec-btn-black tw-rounded-none" @click.prevent="forward">
      <i class="icon icon-fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { backToPreviousPage, forwardToNextPage } from '@/helper/url/page-control';

const setup = () => {
  const route = useRoute();
  const router = useRouter();

  const back = () => {
    backToPreviousPage({ route, router });
  };
  const forward = () => {
    forwardToNextPage({ route, router });
  };

  return {
    back,
    forward,
  };
};

export default {
  name: 'VirtualShelfPageControl',
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/zindex.scss';

.virtual-shelf-page-control {
  position: fixed;
  @include zindex(back-to-top);
  bottom: 10%;
  right: 5%;
}
.btn-local {
  @include pxToRem(width, 88);
  @include pxToRem(height, 138);
  opacity: 0.4;
  background-color: var(--black);
  &:hover {
    opacity: 0.7;
  }
}
.btn-local + .btn-local {
  @include pxToRem(margin-left, 12);
}
.icon {
  font-size: 4rem;
  &:before {
    color: var(--white);
  }
}
</style>
