export const adTypeEnum = {
  home: 'HomePage',
  home2024: '2024NewHomePage',
  global: 'GlobalPage',
  product: 'ProductPage',
  memberCenter: 'MemberCenterPage',
  categoryLevel1: 'L1Page',
  categoryLevel2: 'L2Page',
  categoryLevel3: 'L3Page',
  cart: 'CartPage',
  brandLevel2: 'BrandL2Page',
  brandLevel3: 'BrandL3Page',
};

export const productAdTypeEnum = {
  horizontalPaint: 'horizontal_paint',
};
