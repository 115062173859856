<template>
  <BannerBoxMobile :show-banner="hasTabs" :title="title">
    <template #body>
      <ul v-if="tabs.length > 0" v-show="!hiddenTab" ref="tabsRef" class="tabs">
        <li
          v-for="(tab, index) in formattedTabs"
          :id="tabID(tabData, index)"
          :key="index"
          :class="{ active: currentTab === tab.index, empty: !tab.title }"
          @click="tab.index !== undefined && selectTab(tab.index)"
        >
          {{ tab.title || '' }}
        </li>
      </ul>
      <div v-if="isTabActive(currentTab) && !$isEmpty(tabs[currentTab]?.products)" ref="productsBlockElement" class="products-wrapper">
        <RowProducts :products="tabs[currentTab]?.products" :tab-data="tabData" :product-data="productData" :current-tab="currentTab" />
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import RowProducts from '@/components/layout/elements/mobile/row-products.vue';
import { isEmptyValue } from '@/helper/data-process';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
  tabs: { type: Array, required: true },
  hiddenTab: { type: Boolean, default: false },
  tabData: { type: String, default: '' }, // tag 欄位的 ga ID 標籤
  productData: { type: String, default: '' }, // product 欄位的 ga ID 標籤
});

const { title, tabs, hiddenTab, tabData, productData } = toRefs(props);
const priceAndImage = usePriceAndImageStore();
const { fetchPrice } = priceAndImage;

const first = ref(true);
const currentTab = ref(0);
const loadedTabs = ref(new Set());
const tabsRef = ref(null);

/** @const {method} tabID tab列的 id */
const tabID = (id = '', index = 0) => (!isEmptyValue(id) ? `${id}-${index + 1}` : '');

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN || x.guid || x.product_guid, tabs.value[currentTab.value]?.products || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const productsBlockElement = ref(null);

const selectTab = async (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return;
  currentTab.value = index;
  loadedTabs.value.add(index);
  await getProductPrices();

  if (productsBlockElement?.value) await productsBlockElement.value.scroll({ left: 0, behavior: 'smooth' });

  const left = await (tabsRef.value.querySelector('.active').offsetLeft - 16);
  await tabsRef.value.scroll({ left });
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = async (nv) => {
  if (nv === undefined || tabs.value.length <= 0) return false;
  currentTab.value = first.value && tabs.value?.length > 1 ? randomIfNotTest(tabs.value?.length - 1, 0) : 0;
  await selectTab(currentTab.value, false);
  if (first.value === true) first.value = false;
  return true;
};

const isTabActive = (index) => loadedTabs.value.has(index);

const hasTabs = computed(() => tabs.value.length > 0 && tabs.value[0]?.products.length > 0);

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.title, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.title, index }));
};

// tab 用的加料標籤 (name | empty | name | empty | name ...)
const formattedTabs = computed(() => insertSpaces(tabs.value));

onMounted(() => {
  watch(tabs, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
.tabs {
  @apply tw-p-0 tw-mb-5 tw-flex tw-list-none tw-overflow-auto;
}
.tabs li {
  @apply tw-p-[8px] tw-text-[14px] tw-leading-[22px] tw-font-medium tw-rounded tw-flex-shrink-0 tw-cursor-pointer;
  color: theme('colors.gray-500');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    @apply tw-leading-[18px] tw-cursor-default;
    color: theme('colors.gray-200');
    &::before {
      @apply tw-text-[10px];
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}
.products-wrapper {
  @apply tw-overflow-auto;
}
</style>
