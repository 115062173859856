<template>
  <div v-if="hasItems" :style="{ backgroundColor: getSmallBannerSlide.color || '#FDECA7' }" class="tw-mb-4">
    <div class="title"><img :src="getSmallBannerSlide.imageMobile" :alt="getSmallBannerSlide.alt" /></div>

    <div v-if="items.length > 0">
      <div class="banner-wrapper">
        <router-link-usage v-for="(item, index) in items" :id="`home-strategy-${index + 1}`" :key="`${index}`" :link="item.link" :title="item.alt" class="banner"
          ><img v-lazy="item.image" :alt="item.alt"
        /></router-link-usage>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';

const homeAdStore = useHomeAd2024Store();
const { getSmallBannerSlide } = storeToRefs(homeAdStore);

const items = computed(() => getSmallBannerSlide.value?.items || []);
const hasItems = computed(() => items.value.length > 0);
</script>

<style scoped>
.title {
  @apply tw-mb-[8px];
  img {
    @apply tw-w-full;
  }
}

.banner-wrapper {
  @apply tw-flex tw-p-[16px] tw-pt-[8px] tw-pb-[24px] tw-overflow-auto;
}

.banner {
  @apply tw-w-[100px] tw-h-[148px] tw-flex tw-mr-[8px] tw-rounded-lg tw-overflow-hidden;
  flex-shrink: 0;
}
</style>
