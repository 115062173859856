<template>
  <div ref="btpn" class="Back-to-top" @click="btp">
    <div class="icon-arrow-up-solid"></div>
  </div>
</template>

<script setup>
import throttle from 'lodash-es/throttle';
import { ref, toRefs, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { isEmptyValue } from '@/helper/data-process';
// 與 底部 或 relative-container (ex. footer) 間距 (tabbar 高度 72, right間距15)
const bottomSpace = 87;

const props = defineProps({ relativeContainerSelector: { type: String, default: '' } });

const { relativeContainerSelector } = toRefs(props);
const route = useRoute();
const $screen = inject('$screen');
const btpn = ref(null);
// 需要Back to top按鈕
const routeName = ref([
  'index',
  'product-id',
  'category-1-id',
  'category-2-id',
  'category-3-id',
  'search',
  'brand',
  'best-sellers-bookstore',
  'eslite-choice-books',
  'eslite-choice-music',
  'eslite-only',
]);

/**
 * Methods
 */
const checkPage = () => btpn.value?.classList[routeName.value.includes(route.name) ? 'remove' : 'add']('no-page');

const checkAndSetRelativePosition = () => {
  if (!btpn.value) return false;
  if (!relativeContainerSelector.value) return false;
  const relativeContainerHeight = document.querySelector(relativeContainerSelector.value)?.offsetHeight || 0;
  // 判斷是否接近 relative-container (ex. footer) 底端
  if (window.scrollY + window.innerHeight > document.body.clientHeight - relativeContainerHeight) {
    if (!isEmptyValue(btpn.value)) {
      btpn.value.style.cssText = `position: absolute; bottom: calc(100% + ${bottomSpace}px);`;
    }
    return true;
  }
  return false;
};

const setFixedPosition = () => {
  if (isEmptyValue(btpn.value)) return;
  btpn.value.style.position = `fixed`;
  // 行動版有add-cart要有間距
  if ($screen.isMobileSize && route.name === 'product-id') {
    const addCart = document.querySelector('.add-cart');
    btpn.value.style.bottom = `${addCart?.offsetHeight + bottomSpace}px`;
    return;
  }
  btpn.value.style.bottom = `${bottomSpace}px`;
};

const scrollEvent = throttle(function () {
  if (isEmptyValue(btpn.value) || btpn.value === undefined) return;

  // 滾動300距離就顯示btp按鈕
  btpn.value?.classList[window.scrollY > 200 ? 'add' : 'remove']('active');

  if (checkAndSetRelativePosition()) return;

  setFixedPosition();
}, 100);

const btp = () => window.scrollTo({ top: 0, behavior: 'smooth' });

onBeforeUnmount(() => window.removeEventListener('scroll', scrollEvent));

onMounted(() => {
  checkPage(btpn.value);
  window.addEventListener('scroll', scrollEvent);
});

watch(
  () => route.name,
  () => {
    checkPage(btpn.value);
  },
);
</script>

<script>
export default { name: 'BackToTop' };
</script>

<style scoped>
.Back-to-top.active.no-page {
  @apply tw-hidden;
}
.Back-to-top.active {
  @apply tw-flex tw-justify-center tw-items-center;
}
.Back-to-top {
  @apply tw-w-[42px] tw-h-[42px] tw-opacity-80 tw-hidden tw-fixed tw-bottom-[3%] tw-right-[1%] tw-z-[15] tw-cursor-pointer tw-rounded-md;
  @apply max-lg:tw-right-[15px];
  background-color: theme('colors.white');
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: theme('colors.black');
    .icon-arrow-up-solid {
      color: theme('colors.white');
    }
  }
}
</style>
