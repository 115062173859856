<template>
  <div v-if="items.length > 0" class="tw-w-full tw-pt-0 tw-px-0 tw-pb-4">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in items" :key="`sliders-${index}`" class="swiper-slide" :title="item.alt">
        <RouterLinkUsage :id="`home-banner-${index + 1}`" :link="item.link" :title="item.alt">
          <swiper-webp-image class="horizontal-img" :src="item.imageMobile" :alt="item.alt" :default-image="bigBannerDefaultImage3x"></swiper-webp-image>
        </RouterLinkUsage>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import SwiperWebpImage from '@/components/utility/image/swiper-webp-image.vue';
import { bigBannerDefaultImage3x } from '@/constant/images-path';
import { modifySwiperOptionForVisualTest } from '@/helper/swiper';

const props = defineProps({
  title: { type: String, default: '' },
  items: { type: Array, default: () => [], required: true },
});

const { items } = toRefs(props);

const swiperOption = modifySwiperOptionForVisualTest({
  loop: true,
  autoplay: { delay: 3000, disableOnInteraction: false },
  enabled: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.horizontal-img img {
  @apply tw-rounded-lg;
}
</style>
