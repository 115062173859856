import { isNil, map, filter, prop, includes } from 'ramda';
import { homePageADMappingEnum } from '@/constant/ad/homepage-ad-type';
import { MemberCenterPageADMappingEnum } from '@/constant/ad/member-ad-type';
import { isFunction, rejectEmpty, isEmptyValue } from '@/helper/data-process';

export const adType = {
  memberCentre: MemberCenterPageADMappingEnum,
  homePage: homePageADMappingEnum,
};

export const createMethodName = (prefix) => (ad, types) => isNil(adType[ad][types]) ? '' : `${prefix}${adType[ad][types]}`;

export const findFormatter = (ad, types) => createMethodName('format')(ad, types);

export const findMutations = (ad, types) => createMethodName('set')(ad, types);

export const findGetter = (ad, types) => createMethodName('get')(ad, types);

export const isExpired = (previousTime, expiredHours) => {
  const current = new Date();
  const addPreviousTimeToSomeHours = new Date(previousTime);
  addPreviousTimeToSomeHours.setHours(previousTime.getHours() + expiredHours);

  return current > addPreviousTimeToSomeHours;
};

export const imagePath = (item) => {
  const img = /^(http)/.test(item.image) ? item.image : `${import.meta.env.VITE_SOURCE_PIC}${item.image}`;
  return { backgroundImage: `url(${img})` };
};

export const processAdCreator =
  ({ mappingEnum, formatterSet, position }) =>
  (inputData) => {
    const filterExisted = (data) => filter((ad) => includes(prop('banner_type', ad), position), data);
    const formatAd = (data) =>
      map((ad) => {
        const mappingName = mappingEnum[ad.banner_type];
        const formatter = formatterSet[`format${mappingName}`];
        if (!isFunction(formatter)) return {};
        return formatter(ad);
      }, data);

    return rejectEmpty(formatAd(filterExisted(inputData)));
  };

export const adIndexByADKeyCreator = (keyEnum) => (adList) => {
  const keyByADKeyReducer = (adSet, ad) => {
    const key = keyEnum[ad.type];
    adSet[key] = ad;
    return adSet;
  };
  return adList.reduce(keyByADKeyReducer, {});
};

export const getNavLinkPath = (levelItem) => (isEmptyValue(levelItem.link) ? `/category/${levelItem.depth}/${levelItem.id}` : levelItem.link);
