<template>
  <div
    v-if="componentName === 'home2024indexLayout' || componentName === 'home2024mobileLayout'"
    ref="appElem"
    class="tw-flex tw-flex-col tw-min-h-screen"
    :class="{ bgc: isMemberProfileUrl, sticky, 'tw-mb-[72px]': componentName === 'home2024mobileLayout' }"
    :data-test-id="componentName === 'home2024indexLayout' ? 'home-2024index-layout' : 'home-2024mobile-layout'"
  >
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus" />

    <eslite-index-header v-if="componentName === 'home2024indexLayout'" :scroll-mode="sticky" />
    <eslite-mobile-header v-else />

    <div class="content-spacer"></div>
    <router-view :scroll-mode="sticky" />
    <loading v-if="getLoadingStatus" />

    <EsliteFooter2024Index v-if="componentName === 'home2024indexLayout'" />
    <EsliteFooter2024Mobile v-else />

    <TabBar2024Mobile v-if="componentName === 'home2024mobileLayout' && !$inApp" />
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import { useLoadingStore } from '@/stores/loading';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useCategoriesStore } from '@/stores/categories';

import { useGetCustomerServiceStore } from '@/composables/store/customer-service';

import EsliteIndexHeader from '@/components/layout/header-2024index.vue';
import EsliteMobileHeader from '@/components/layout/header-2024mobile.vue';
import EsliteFooter2024Index from '@/components/layout/footer-2024index.vue';
import EsliteFooter2024Mobile from '@/components/layout/footer-2024mobile.vue';
import TabBar2024Mobile from '@/components/layout/tab-bar.vue';
import Loading from '@/components/utility/loading.vue';

import { filterUrl } from '@/constant/customer-service';
import { memberProfileStyle } from '@/constant/member-profile';

import { fixedScrollAddListeners, fixedScrollRemoveListeners } from '@/helper/fixed-scrolling';
import { isMobile, isTablet } from '@/helper/ua.js';

const CustomerService = defineAsyncComponent(() => import('@/components/customer-service/customer-service.vue'));

const route = useRoute();
const categoriesStore = useCategoriesStore();
const { fetchCategories, fetchBrandGroup } = categoriesStore;
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const customerServiceStore = useCustomerServiceStore();
const { getCustomerStatus } = useGetCustomerServiceStore();

const isMobileRef = isMobile();
const isTabletRef = isTablet();

const sticky = ref(false);
const appElem = ref(null);

const turnOnCustomerStatus = () => customerServiceStore.setCustomerStatus(true);
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
const isCustomerServiceUrl = computed(() => filterUrl.includes(route.fullPath));
const isShowCustomerService = computed(() => getCustomerStatus.value || isCustomerServiceUrl.value);

const componentName = computed(() => {
  // 虛擬貨架 (新版)
  if (route.query?.layout === 'virtual_shelf') return 'home2024mobileLayout';
  if (isMobileRef || isTabletRef) return 'home2024mobileLayout';
  return 'home2024indexLayout';
});

watch(
  isCustomerServiceUrl,
  (newValue) => {
    if (newValue) turnOnCustomerStatus();
  },
  { immediate: true },
);
onMounted(() => {
  fixedScrollAddListeners('#header-hidden-anchor', appElem, sticky);
  fetchCategories();
  fetchBrandGroup();
});
onUnmounted(fixedScrollRemoveListeners);
</script>
