<template>
  <div v-if="showTabbar" class="tabbar-and-category" data-test-id="footer-bar">
    <!-- Tab Bar -->
    <div ref="tabbarRef" class="tabbar !tw-hidden" :style="`grid-template-columns: repeat(${buttons.length}, auto);`">
      <button v-for="btn in buttons" :id="btn.id" :key="btn.name" :title="btn.name" :class="{ active: btn.isActive }" class="tab" type="button" @click="handleButtonClick(btn)">
        <div ref="iconRefs[btn.name]" class="base-icon">
          <div v-if="btn.name === '購物車' && cartQuantity > 0" class="shopping-cart">
            <span class="font-roboto">{{ cartQuantity }}</span>
          </div>
          <img :src="btn.icon" :alt="btn.name" class="icon" />
          <div class="lottie-box"></div>
        </div>
        <div class="title">{{ btn.name }}</div>
      </button>
    </div>

    <Menu :open-menu="activeModal" data-test-id="mobile-tab-bar-menu" />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import lottie from 'lottie-web';

import { storeToRefs } from 'pinia';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';

import Menu from '@/components/layout/menu-bar-with-mobile2024.vue';

import homeIcon from '@/static/images/icons/tabbar/Home.svg';
import categoriesIcon from '@/static/images/icons/tabbar/Categories.svg';
import saveIcon from '@/static/images/icons/tabbar/Save.svg';
import shoppingIcon from '@/static/images/icons/tabbar/Shopping.svg';
import myIcon from '@/static/images/icons/tabbar/My.svg';

import homeJsonData from '@/static/lottie/tabbar/Home.json';
import categoriesJsonData from '@/static/lottie/tabbar/Categories.json';
import saveJsonData from '@/static/lottie/tabbar/Save.json';
import shoppingJsonData from '@/static/lottie/tabbar/Shopping.json';
import myJsonData from '@/static/lottie/tabbar/My.json';

import { isEmptyValue } from '@/helper/data-process';

// store
const cartItemsStore = useCartItemsStore();
const { initCartQuantity, clearQuantity } = cartItemsStore;
const { getTotalOfCartItems, getQuantity } = storeToRefs(cartItemsStore);
const loginStore = useLoginStore();

const tabbarRef = ref(null);
const router = useRouter();
const route = useRoute();

// 控制顯示哪個 Modal 的狀態
const activeModal = ref(false);
const modalMessage = ref('');

// Lottie animat group
const lottieInstances = ref({});
// 按鈕存放區
const iconRefs = ref({});

/** @const {ref[{}]} buttons 按鈕資訊 */
const buttons = ref([
  // { name: '限時活動 (測試)', isActive: false, icon: categoriesIcon, lottieFile: homeJsonData, type: 'method', route: null, checkRoute: null, methodName: 'showLimitedTimeEvent' },
  { name: '首頁', isActive: false, id: 'menu-home', icon: homeIcon, lottieFile: homeJsonData, type: 'route', route: '/', checkRoute: ['/'] },
  { name: '分類', isActive: false, id: 'menu-category', icon: categoriesIcon, lottieFile: categoriesJsonData, type: 'method', route: null, checkRoute: null, methodName: 'showCategoryContent' },
  { name: '我的收藏', isActive: false, id: 'menu-collections', icon: saveIcon, lottieFile: saveJsonData, type: 'route', route: '/member/wish', checkRoute: ['/member/wish'] },
  { name: '購物車', isActive: false, id: 'menu-cart', icon: shoppingIcon, lottieFile: shoppingJsonData, type: 'route', route: '/cart/step1', checkRoute: ['/cart/step1', '/cart/step2', '/cart/step3'] },
  { name: '會員中心', isActive: false, id: 'menu-member', icon: myIcon, lottieFile: myJsonData, type: 'route', route: '/member', checkRoute: ['/member'] },
]);

// set Lottie animate
const loadLottieAnimation = (buttonName, lottieFile) => {
  if (iconRefs.value[buttonName]) {
    const instance = lottie.loadAnimation({ container: iconRefs.value[buttonName], renderer: 'svg', loop: false, autoplay: true, animationData: lottieFile });
    lottieInstances.value[buttonName] = instance;
  }
};

// destroy Lottie animate
const unloadLottieAnimation = (buttonName) => {
  const instance = lottieInstances.value[buttonName];
  if (instance) {
    instance.destroy();
    delete lottieInstances.value[buttonName];
  }
};

// 更新按鈕狀態 (with route path)
const updateButtonState = (routePath) => {
  buttons.value.forEach((btn) => {
    btn.isActive = btn.checkRoute ? btn.checkRoute.includes(routePath) : false;
    // 根據按鈕狀態加載或卸載 Lottie 動畫
    if (btn.isActive) lottieInstances.value[btn.name]?.goToAndPlay(0);
  });
};

// 設置 method 按鈕的 isActive 狀態
const setActiveMethodButton = (buttonName) => buttons.value.forEach((btn) => (btn.isActive = btn.name === buttonName));

// 顯示分類內容的函式
const showCategoryContent = () => {
  modalMessage.value = '這裡顯示分類內容';
  activeModal.value = true;
  setActiveMethodButton('分類');
};

// 顯示限時活動的函式 (測試)
const showLimitedTimeEvent = () => {
  modalMessage.value = '這裡顯示限時活動內容';
  activeModal.value = true;
  setActiveMethodButton('限時活動');
};

// method group
const methods = { showCategoryContent, showLimitedTimeEvent };

// 取消所有 methods 呼叫 (關閉 Modal method)
const closeModal = () => {
  activeModal.value = false;
  buttons.value.forEach((btn) => {
    if (btn.type === 'method') btn.isActive = false;
  });
};

const callMethod = (methodName) => (methods[methodName] && typeof methods[methodName] === 'function' ? methods[methodName]() : false);

// click 更新按鈕狀態
const handleButtonClick = async (button) => {
  const activeBtn = await buttons.value.find((x) => x.isActive === true);

  if (button.type === 'method' && activeBtn?.type === 'method' && button.name === activeBtn?.name) {
    console.log(`[事件按鈕] 點擊同一按鈕`);
    return false;
  }

  activeModal.value = false;
  // 只有點擊 method type 時需要改狀態， route 狀態由 watch 觸發
  if (activeBtn?.type === 'method') buttons.value.forEach((btn) => (btn.isActive = btn.checkRoute !== null ? btn.checkRoute.includes(button.route) : false));

  // if (activeBtn?.id !== undefined && activeBtn?.id === button.id && button.type === 'method') { console.log(`事件按鈕重複點擊：關閉 active`); return false; }
  if (button.type === 'route' && activeBtn?.type === 'method' && button.checkRoute?.includes(route.path) === true) {
    console.log(`[跳頁按鈕] 點擊與展開功能時頁面相同的連結按鈕時： 關閉 method`);
    return false;
  }

  if (button.type === 'route' && button.route) {
    console.log(`[跳頁按鈕] 轉跳`);
    router.push(button.route);
  } else if (button.type === 'method' && button.methodName) {
    console.log(`[事件按鈕] 呼叫事件`);
    callMethod(button.methodName);
  }

  return lottieInstances.value[button.name].goToAndPlay(0);
};

const totalOfCartItems = computed(() => getTotalOfCartItems.value);
const storeCartQuantity = computed(() => getQuantity.value);
const isLogin = computed(() => loginStore.loggedIn);

// 未登入一律拿 local cart
const cartQuantity = computed(() => (!isLogin.value ? totalOfCartItems.value : storeCartQuantity.value));

/** @const {string[]} displayPage 可顯示 tabbar 的 page component name */
const displayPage = [
  'index', 'search', 'brand', 'brand-id', 'brand-id-category',
  'category-1-id', 'category-2-id', 'category-3-id',
  'best-sellers-online', 'best-sellers-online-l1', 'best-sellers-online-l2', 'best-sellers-online-l3',
  'best-sellers-bookstore', 'eslite-only', 'eslite-choice-books', 'eslite-choice-music',
  'eslite-only', 'eslite-choice-books', 'eslite-choice-music',
  'member', 'member-wish', 'member-points', 'member-coupons', 'member-notify',
  'member-orders-page', 'member-account', 'member-profile-modify-profile',
  'member-profile-modify-password', 'member-profile', 'member-profile-complete-password',
  'member-newsletter', 'member-customer-customer-service',
  'docs-group-buying-description', 'docs-membership-level',
  'docs-aboutcoupon', 'docs-personal-protection-act', 'docs-privacy-policy', 'docs-service-rules',
];
/** @const {computed} showTabbar 依照頁面路徑判斷是否顯示 tabbar */
const showTabbar = computed(() => (!isEmptyValue(route.name) ? displayPage.includes(route.name) : false || false));

watch(
  route,
  async (nv, ov) => {
    if (nv.path) updateButtonState(nv.path);
    closeModal();
  },
  { immediate: true, deep: true },
);

onMounted(async () => {
  // init
  initCartQuantity();
  await buttons.value.forEach((btn, index) => {
    iconRefs.value[btn.name] = tabbarRef.value?.querySelectorAll(`.base-icon`)[index]?.querySelector('.lottie-box');
    loadLottieAnimation(btn.name, btn.lottieFile);
  });
  await updateButtonState(route.path);
  tabbarRef.value?.classList.remove('!tw-hidden');
});

// destroy Lottie animate
onBeforeUnmount(() => Object.keys(lottieInstances.value).forEach((buttonName) => unloadLottieAnimation(buttonName)));
onUnmounted(clearQuantity);
</script>

<script>
export default { name: 'TabBar2024' };
</script>

<style scoped>
.tabbar {
  @apply tw-w-full tw-h-[72px] tw-grid tw-gap-6 tw-justify-center tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-10;
  background-color: theme('colors.white');
  box-shadow: 0px 1px 0px 0px #ededed inset;
}

.tab {
  @apply tw-w-12 tw-h-[42px] tw-p-0 tw-mt-[6px] tw-flex tw-flex-col tw-items-center tw-no-underline tw-border-none tw-relative;
}

.icon,
.base-icon {
  @apply tw-w-6 tw-h-6 tw-mb-1 tw-mx-auto tw-text-center tw-relative;
}
.shopping-cart {
  @apply tw-w-4 tw-h-4 tw-absolute tw-top-[-3px] tw-left-[18px] tw-z-10 tw-rounded-2xl tw-text-[10px] tw-px-1 tw-py-0 tw-inline;
  color: theme('colors.white');
  &::after {
    content: '';
    @apply tw-w-full tw-h-full tw-rounded-xl tw-absolute tw-top-0 tw-left-0 tw-z-10;
    background-color: #a7212f;
  }

  span {
    @apply tw-w-4 tw-absolute tw-top-[1px] tw-left-0 tw-z-[11] tw-text-center;
    transform: scale(0.9);
  }
}

.lottie-box,
.lottie-box :deep(svg) {
  @apply tw-w-6 tw-h-6 tw-mb-1 tw-mx-auto tw-text-center;
}

.title {
  @apply tw-text-[10px] tw-leading-[14px] tw-no-underline;
  color: theme('colors.gray-400');
}

.tab {
  .icon {
    @apply tw-block;
  }
  .lottie-box {
    @apply tw-hidden;
  }
}

.tab.active {
  .icon {
    @apply tw-hidden;
  }
  .lottie-box {
    @apply tw-block;
  }
}

.tab.active {
  .title {
    color: theme('colors.gold-500');
  }
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-black/50;
  z-index: 1000; /* 確保 Modal 在最上層 */
}

.modal-content {
  @apply tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-w-72 tw-text-center;
}

.close-button {
  @apply tw-mt-4 tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md;
}
</style>
