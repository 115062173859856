<template>
  <div v-if="openMenu" class="menu-bar tw-row">
    <div class="menu-content">
      <div class="menu-title">分類</div>

      <div v-if="hasRanks" class="link-list">
        <div class="link-list-scroll">
          <router-link-usage v-for="(item, index) in ranks" :id="`home-a004-${index + 1}`" :key="`menu-rank-${item.sort}`" :link="item.link" :title="item.alt" class="ranks">
            <img v-lazy="item.image" :alt="item.alt" src="" />
            <p>{{ item.alt }}</p>
          </router-link-usage>
        </div>
      </div>

      <div class="list">
        <div class="padding-line"></div>
        <div v-if="!hasData"><loading /></div>
        <div v-else ref="boxRef" class="box tw-flex tw-w-full">
          <!-- 館別 L1 列表 -->
          <div ref="leftElemRef" class="category l1-column" :style="categoryHeight">
            <div
              v-for="(item, index) in categories"
              :key="`menu-l1-${index}-${item.id}`"
              class="category-l1"
              :class="l1ActiveClass(index)"
              :title="item.is_brand ? '品牌旗艦' : item.description"
              data-test-id="level1"
              @click="setActive(index)"
            >
              <div class="category-l1-text">{{ item.is_brand ? '品牌旗艦' : item.description }}</div>
            </div>
          </div>
          <!-- 館別 L2, 3 -->
          <div ref="rightElemRef" class="category l2-l3-column" :style="categoryHeight" data-test-id="selected-category-detail">
            <!-- L1 標題 (category) -->
            <router-link
              v-if="!categoryL1IsBrand"
              :key="`menu-l1-top`"
              class="l1-active-link"
              :to="categoryLinkTo(categoryL1Active)"
              :style="categoryL1ActiveStyle"
              :title="categoryL1ActiveDescription"
            >
              <div class="l1-active-link-text">{{ categoryL1ActiveDescription }}</div>
              <span>逛賣場</span>
            </router-link>
            <!-- L2 標題 -->
            <template v-if="!categoryL1IsBrand">
              <div v-for="(item2, index2) in categoryL2" :key="`menu-l2-${index2}-${item2.id}`" class="l2-row">
                <a v-if="isCustomLink(item2)" :href="categoryLinkTo(item2)" :title="item2.description" class="l2-title">
                  <div class="tw-col-10 l2-title-link-text">{{ item2.description }}</div>
                  <i class="tw-col-auto l2-title-link-icon icon-fa-chevron-right"></i>
                </a>
                <router-link v-else :to="categoryLinkTo(item2)" :title="item2.description" class="l2-title">
                  <div class="tw-col-10 l2-title-link-text">{{ item2.description }}</div>
                  <i class="tw-col-auto l2-title-link-icon icon-fa-chevron-right"></i>
                </router-link>
                <!-- L3 標題 -->
                <div class="l3-list-row">
                  <div v-for="(item3, index3) in item2.children || []" :key="`menu-l3-${index2}-${item2.id}-${index3}-${item3.id}`" class="tw-inline-flex tw-w-full">
                    <a v-if="isCustomLink(item3)" class="l3-title" :href="categoryLinkTo(item3)" :title="item3.description">
                      <div class="l3-title-link-text">{{ item3.description }}</div>
                    </a>
                    <router-link v-else class="l3-title" :to="categoryLinkTo(item3)" :title="item3.description">
                      <div class="l3-title-link-text">{{ item3.description }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="(brandGroup, index2) in getBrandGroup" :key="`menu-l2-${index2}`" class="l2-brand-row">
                <div class="l2-brand-title">{{ brandGroup.name }}</div>
                <!-- L3 標題 -->
                <router-link-usage
                  v-for="(brand, index3) in brandGroup.items || []"
                  :key="`brand-l3-${index3}`"
                  :link="brand.is_activity_link ? brand.link : `/brand/${brand.id}`"
                  :title="brand.name"
                  class="l3-brand-title"
                >
                  <lazy-webp-image :src="brand.app_logo_image" :alt="brand.name" :is-lazy="true" class="brand-img" />
                </router-link-usage>
              </div>
            </template>
          </div>
          <div class="tw-w-full tw-pb-4"></div>
        </div>
      </div>
    </div>
    <div class="menu-bg"></div>
  </div>
</template>
<script setup>
import { sortBy, prop } from 'ramda';
import { inject, reactive, ref, toRefs, computed, watch, onUnmounted, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCategoriesStore } from '@/stores/categories';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import Loading from '@/components/utility/inline-loading-big.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import LazyWebpImage from '@/components/utility/image/lazy-webp-image.vue';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';

import { categoryLinkTo } from '@/helper/path';
import { isEmptyValue } from '@/helper/data-process';

const sortByBoolean = (item) => {
  const sortValue = prop('is_brand', item);
  return sortValue === false || isEmptyValue(sortValue) ? 999 : 1;
};
const defaultColor = '#000';

const props = defineProps({
  /** 是否開啟 menu */
  openMenu: { type: Boolean, default: false },
});

const { openMenu } = toRefs(props);
const $screen = inject('$screen');
const categoryHeight = reactive({ height: '' });
const l1ActiveNum = ref(0);
const boxRef = ref(null);
const leftElemRef = ref(null);
const rightElemRef = ref(null);

/** Store */
const categoriesStore = useCategoriesStore();
const { fetchCategories, fetchBrandGroup } = categoriesStore;
const { getCategories, getBrandGroup } = storeToRefs(categoriesStore);
const homeAd2024Store = useHomeAd2024Store();
const { getTenLinks } = storeToRefs(homeAd2024Store);
const { fetchHomeAd: fetchHomeAd2024 } = homeAd2024Store;

/**
 * computed
 */
const ranks = computed(() => getTenLinks.value?.items || []);
const hasRanks = computed(() => ranks.value.length > 0);
const screen = computed(() => $screen);
const l1Active = computed({
  get() {
    return l1ActiveNum.value;
  },
  set(index) {
    l1ActiveNum.value = index;
  },
});
const categories = computed(() => sortBy(sortByBoolean, getCategories.value || []));
const hasData = computed(() => categories.value.length > 0);
const categoryL1Active = computed(() => categories.value[l1Active.value] || { id: '', description: '', children: [], color: '', key: '', depth: 1 });
const categoryL1IsBrand = computed(() => categoryL1Active.value.is_brand || false);
const categoryL1ActiveDescription = computed(() => (categoryL1IsBrand.value ? '品牌旗艦' : categoryL1Active.value.description || ''));
const categoryL1ActiveStyle = computed(() => ({ color: categoryL1Active.value?.color || defaultColor }));
const categoryL2 = computed(() => categoryL1Active.value.children);

/**
 * Methods
 */
const setHeight = () => {
  document.body.style.overflow = openMenu.value ? 'hidden' : '';
  const menuTitle = 48; // .menu-title
  const linkList = document.querySelector('.link-list');
  const linkListHeight = linkList === null ? 0 : 82; // 連結組
  const emptyHeight = 12; // 間距
  const tabBar = 72; // 下方 tab bar
  const height = $screen.height - (menuTitle + linkListHeight + emptyHeight + tabBar);
  categoryHeight.height = `${height}px`;
};
const setActive = (index) => {
  l1Active.value = index;
  rightElemRef.value?.scrollTo({ top: 0 });
};
// L1 class
const l1ActiveClass = (index) => ({ active: index === l1Active.value });

// 是指定路徑
const isCustomLink = (item) => !isEmptyValue(item?.link);

// menu 展開時調整高度
watch(openMenu, () => setHeight());
// 畫面調整時調整高度
watch(screen, () => setHeight(), { deep: true, immediate: true });

onUnmounted(() => document.body.style.removeProperty('overflow'));
onMounted(() => {
  fetchCategories();
  fetchBrandGroup();
  fetchHomeAd2024([homePageADTypeEnum.tenLinks]);
  setTimeout(() => setHeight(), 1000);
});
</script>

<script>
export default { name: 'MenuBarWithMobile2024' };
</script>

<style scoped>
/* 內容框 */
.menu-content {
  @apply tw-w-full tw-px-0 tw-mx-0 tw-h-[calc(100%-72px)] tw-z-[2001];
}
/* 背景白 */
.menu-bg {
  @apply tw-w-full tw-h-[calc(100%-72px)] tw-fixed tw-z-[2000];
  background-color: theme('colors.white');
}

/* menu-bar 外框 */
.menu-bar {
  @apply tw-w-full tw-h-[calc(100%-72px)] tw-m-auto;
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-[2000] tw-select-none tw-overflow-auto;
  background-color: theme('colors.white');
}

.menu-title {
  @apply tw-w-full tw-h-12 tw-px-[52px] tw-py-[6px];
  @apply tw-font-sans-ch tw-text-base tw-leading-[22px] tw-font-medium tw-text-center;
  @apply tw-flex tw-items-center tw-justify-center;
  background-color: theme('colors.white');
  color: theme('colors.gray-800');
}

.line {
  @apply tw-h-[1px];
  background-color: theme('colors.gray-200');
}
.padding-line {
  @apply tw-h-3 tw-flex;
  background-color: theme('colors.gray-80');
}

.link-list {
  @apply tw-relative tw-overflow-auto tw-pb-3;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);

  .link-list-scroll {
    @apply tw-w-full tw-flex tw-gap-4 tw-snap-x tw-overflow-x-auto;
  }
}
.ranks {
  @apply tw-w-[52px] tw-text-center tw-snap-start tw-scroll-ml-6 tw-shrink-0 tw-relative first:tw-ml-4 last:tw-mr-4;
  a {
    @apply tw-text-center;
  }
  img {
    @apply tw-relative tw-shrink-0 tw-bg-white;
    @apply tw-w-[50px] tw-h-[50px];
  }
  p {
    @apply tw-pt-1 tw-pb-0 tw-mx-auto tw-my-0 tw-text-xs tw-font-normal tw-text-center;
    color: theme('colors.gray-800');
  }
}

.category {
  @apply tw-pr-4 tw-pl-0 tw-py-0 tw-overflow-x-hidden tw-overflow-y-auto;

  &:first-child {
    @apply tw-p-0;
  }
}

.category-l1 {
  @apply tw-h-[52px] tw-py-4 tw-m-auto tw-text-center tw-text-sm tw-font-normal tw-font-sans-ch;
  color: theme('colors.gray-800');

  .category-l1-text {
    @apply tw-w-full tw-p-0 tw-mx-auto tw-whitespace-pre tw-overflow-hidden tw-text-ellipsis;
  }

  &.active {
    @apply tw-font-medium;
    color: theme('colors.gold-500');
    background-color: theme('colors.gold-100');
  }
}

.l1-column {
  max-width: 88px;
  flex: 0 0 88px;
  margin-right: 10px;
  background-color: theme('colors.white');
}

.l2-l3-column {
  max-width: calc(100% - 98px);
  flex: 0 0 calc(100% - 98px);
}

/* 右 L1 名稱連結方塊 */
.l1-active-link {
  @apply tw-p-4 tw-mb-3 tw-flex tw-items-center tw-flex-row tw-justify-between tw-rounded-lg;
  background-color: theme('colors.white');
}

.l1-active-link-text {
  @apply tw-text-base tw-leading-[22px] tw-font-medium;
  color: theme('colors.gold-500');
  & + span {
    @apply tw-text-right tw-text-[13px] tw-leading-[22px] tw-font-normal;
    color: theme('colors.gold-300');
  }
}

.l2-row {
  @apply tw-w-full tw-p-4 tw-mb-3 tw-block tw-rounded-lg;
  background-color: theme('colors.white');
  &:last-child {
    @apply tw-mb-3;
  }
}

.l2-brand-row {
  @apply tw-w-full tw-p-4 tw-mb-3 tw-flex tw-flex-row tw-flex-wrap tw-rounded-lg tw-gap-2;
  background-color: theme('colors.white');
}

.l2-brand-title {
  @apply tw-w-full tw-p-1 tw-mb-2 tw-items-center tw-text-sm tw-font-medium tw-flex tw-justify-between tw-flex-wrap;
  color: theme('colors.gold-500');
}

.l2-title {
  @apply tw-mx-0 tw-w-full tw-p-1 tw-items-center tw-text-sm tw-flex tw-justify-between tw-flex-wrap;
  color: theme('colors.gold-500');
}

.l2-title-link-text {
  @apply tw-p-0 tw-font-sans-ch tw-font-bold tw-whitespace-pre tw-overflow-hidden tw-text-ellipsis;
}

.l2-title-link-icon {
  @apply tw-w-4 tw-h-4 tw-p-0 tw-text-right tw-flex tw-justify-center tw-content-center tw-items-center;
  color: theme('colors.black');
}

.l3-title {
  @apply tw-w-full tw-px-1 tw-py-2 tw-mx-0 tw-text-13px tw-font-normal;
  color: theme('colors.gray-500');
  background: theme('colors.white');
  i {
    @apply tw-hidden;
  }

  &.nuxt-link-active {
    @apply tw-font-bold;
    i {
      @apply tw-flex tw-pr-[0.125rem];
    }
  }
}
.l3-list-row {
  @apply tw-block tw-w-full;
}
.l3-list-row > div:nth-child(even) .l3-title,
.l3-list-row > div:nth-child(even) {
  border-right: none;
}

.l3-title-link-text {
  @apply tw-p-0 tw-whitespace-pre tw-overflow-hidden tw-text-ellipsis;
}

/* 列表 */
.list {
  @apply tw-text-[16px] tw-p-0 tw-m-0 tw-overflow-y-auto tw-overflow-x-hidden;
  background-color: theme('colors.gray-80');
  color: theme('colors.black');
}

.l3-brand-title {
  @apply tw-w-[76px] tw-h-[76px] tw-flex;
  @media (max-width: 389px) {
    @apply tw-w-[30%] tw-max-w-[76px] tw-h-auto;
  }
}

.brand-img {
  @apply tw-w-[76px] tw-h-[76px] tw-rounded-lg;
  @media (max-width: 389px) {
    @apply tw-w-[100%] tw-max-w-[76px] tw-h-auto;
  }
  :deep(img) {
    @apply tw-rounded-lg;
  }
}

a,
a:hover {
  @apply tw-no-underline;
}

i::before {
  color: inherit;
}
</style>
