<template>
  <div v-if="showBanner" class="banner-box">
    <div class="banner-box-title-row">
      <div class="title">{{ title }}</div>
      <slot name="subTitle" alt="同一層置右位置" />
    </div>
    <div class="banner-box-body">
      <slot name="body" alt="內容" />
    </div>
  </div>
</template>

<script setup>
/**
 * 首頁改版 banner 共用框架
 * 包含標題、標題按鈕組及版位內容
 */
import { toRefs } from 'vue';

const props = defineProps({
  /** @const {Boolean} showBanner 顯示版位 */
  showBanner: { type: Boolean, default: false },
  /** @const {String} title 版位標題 */
  title: { type: String, default: '' },
});
const { showBanner, title } = toRefs(props);
</script>

<style scoped>
.banner-box {
  @apply tw-w-[1280px] tw-max-w-full tw-m-0 tw-px-0 tw-pt-0 tw-pb-16;
}
.banner-box-title-row {
  @apply tw-pt-2 tw-pb-5 tw-text-[24px] tw-leading-7 tw-font-bold;
  @apply tw-flex tw-justify-between tw-items-center;
  border-bottom: 1px dashed theme('colors.gray-400');
}
.title {
  @apply tw-text-[24px] tw-leading-7 tw-font-bold;
  @apply tw-flex tw-justify-end;
  color: theme('colors.gray-800');
}
.banner-box-body {
  @apply tw-w-full tw-pt-8 tw-flex tw-flex-col;
}
</style>
