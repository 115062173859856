<template>
  <div class="loading tw-row"><span class="center tw-self-center loading-animate"></span></div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/zindex.scss';

.loading {
  width: 100%;
  height: 100%;
  min-height: 140px;
  text-align: center;
  margin: 0 auto;
  padding: 0;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include zindex(loading);
  background: rgba(0, 0, 0, 0.4);

  .center {
    margin: 0 auto;
  }
  $loadingwidth: 81px;
  $loadingheight: 54px;
  .loading-animate {
    width: $loadingwidth;
    height: $loadingheight;
    background-image: url('@/static/images/loading_w.gif');
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 100% 100%;
    @include mediaMax($grid-breakpoints-lg) {
      width: $loadingwidth * 0.85;
      height: $loadingheight * 0.85;
    }
    @include mediaMax($grid-breakpoints-sm) {
      width: $loadingwidth * 0.75;
      height: $loadingheight * 0.75;
    }
  }
}
</style>
