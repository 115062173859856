/* eslint-disable class-methods-use-this */
import { def, defKey } from '@/constant/auth';
import { getCookie, setCookie } from '@/helper/cookie';
import checkIsInlineApp from '@/helper/check-inline-app';

const type = import.meta.env.VITE_TYPE;
export default class AuthStorageService {
  constructor() {
    this.isLogin = 'isLogin';
  }

  getStorage(key = '') {
    // 若是在 app webview 時相容舊版 token key 雙版本同步運行，等 app 強更後才可以刪除
    if (checkIsInlineApp() && type !== 'local') {
      const oldKey = key.replace(`.${type}`, '.local');
      // console.group(`[auth][app] get token: [${key}, ${oldKey}]`); console.log(getCookie(oldKey)); console.groupEnd();
      if (getCookie(oldKey) !== defKey.false && getCookie(oldKey) !== undefined) return getCookie(oldKey);
      // else 同下 return
    }

    return getCookie(key) || localStorage.getItem(key);
  }

  setStorage(key = '', value = '', hour = 720) {
    // 若是在 app webview 時相容舊版 token key 雙版本同步運行，等 app 強更後才可以刪除
    if (checkIsInlineApp() && type !== 'local') {
      const oldKey = key.replace(`.${type}`, '.local');
      // console.group(`[auth][app] set token: [${key}, ${oldKey}]`); console.log({ key: oldKey, val: value }); console.groupEnd();
      setCookie(oldKey, value, hour);
    }

    setCookie(key, value, hour);
    localStorage.setItem(key, value);
  }

  setToken(token) {
    const tokenStr = token ? token.replace(/(Bearer )/g, '') : defKey.false;
    const useToken = tokenStr === defKey.false ? tokenStr : `${defKey.bearer}${tokenStr}`;
    this.setStorage(defKey.strategy, def.strategy); // 30天
    this.setStorage(`${defKey.token}`, useToken);
    this.setStorage(`${defKey.refreshToken}`, defKey.false);
  }

  setIsLogin(login = false) {
    this.setStorage(this.isLogin, login);
  }

  setLogout() {
    this.setStorage(`${defKey.token}`, defKey.false);
    this.setStorage(this.isLogin, false);
  }

  getToken(type = defKey.token) {
    return this.getStorage(type) || '';
  }

  getIsLogin() {
    return JSON.parse(this.getStorage(this.isLogin));
  }
}
