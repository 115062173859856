<template>
  <div id="header-search-bar">
    <form class="ec-input-group tw-flex tw-pb-[10px]" method="get" action="/Search" @submit="validate">
      <input
        v-bind="$attrs"
        v-model="query"
        class="ec-form-control"
        type="text"
        name="query"
        aria-label="搜尋"
        placeholder="請輸入關鍵字"
        data-test-id="static-searchbar-input"
        autocomplete="off"
        @keyup="keyUp"
        @keydown="keyDown"
        @focusout="focusOut"
        @focus="focusInput"
      />
      <button ref="searchButton" class="ec-btn ec-btn-outline-secondary tw-mx-0 tw-px-0" type="submit">
        <i class="icon-fa-search"></i>
      </button>
      <!-- auto complete-->
      <search-bar-auto-complete
        v-if="isShowRecommendList"
        :display="isShowRecommends"
        :list="items"
        :value="hoverIndex"
        :keyword="searchKeyword"
        :source-type="keywordType"
        @select="selectRecommendedWords"
        @overItem="over"
      ></search-bar-auto-complete>
    </form>
  </div>
</template>

<script setup>
/**
 * 2024首頁改版 header search bar
 */
import { onMounted, inject, ref, watch, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { includes } from 'ramda';
import { useSearchFacetStore } from '@/stores/search-facet';
import SearchBarAutoComplete from '@/components/search/search-bar-auto-complete.vue';
import { useSearchAutoComplete } from '@/composables/search/auto-complete';
import { useSearchBarInit } from '@/composables/search/search-bar-init';
import { isEmptyValue } from '@/helper/data-process';
import { hoverDefaultIndex } from '@/constant/search/auto-complete';
import { searchRoute, categorySearchRoutes } from '@/constant/search/check-params';
import { getGoogleAnalyticsClientId, gtmSearch } from '@/helper/gtm';
import { bridgewellTrack } from '@/helper/tracking/bridgewell';
import { useAbTest } from '@/composables/utility/ab-test';
import { generateTypesEnum } from '@/constant/ab-test/ab-test';
import { abTestCookies } from '@/constant/ab-test/cookies-collection';
import { useLocalRecommendEvent } from '@/composables/search/local-recommend-event';

const props = defineProps({
  isFocus: { type: Boolean, default: false },
});
const { isFocus } = toRefs(props);

const emits = defineEmits(['focusout', 'typing', 'input']);
const $gtm = inject('$gtm');
const route = useRoute();
const searchFacetStore = useSearchFacetStore();
const searchFormRef = ref(null);
const searchBarRef = ref(null);
const searchButtonRef = ref(null);

const query = ref('');
const { exp } = useAbTest(generateTypesEnum.probability, abTestCookies.search, getGoogleAnalyticsClientId());
useSearchBarInit({ query });
const {
  isShowRecommends,
  searchKeyword,
  hoverIndex,
  keywordType,
  items,
  isShowRecommendList,
  searchToken,
  selectRecommendedWords,
  isInvalidHover,
  focusOut,
  focusInput,
  over,
  keyDown,
  keyUp,
  inputComplete,
  setLocalRecord,
} = useSearchAutoComplete({ searchFormRef, searchButtonRef, query, emit: emits });

const { setLocalRecommendEventLog } = useLocalRecommendEvent();

const validate = (event) => {
  const isSelectRecommends = !isInvalidHover() && isShowRecommends.value === true;
  const emptyInput = isEmptyValue(query.value);

  if (isSelectRecommends || emptyInput) {
    event.preventDefault();
    return false;
  }
  // auto complete 相關:
  if (hoverIndex.value === hoverDefaultIndex) {
    setLocalRecord();
    setLocalRecommendEventLog(query.value, searchToken.value, items.value);
  }
  gtmSearch(query.value, $gtm);
  // Note: 如果用宇匯Chrome擴充套件觀察，須在下一行加debugger，再觀查，否則form action直接跳轉search頁面，就看不到目前紀錄
  bridgewellTrack.sendSearch({ searchString: query.value });
  searchFacetStore.clearSearchFacets();
  return true;
};

watch(query, (newValue, oldValue) => {
  // 搜尋頁面（館別/品牌？）因為有預設值，一進頁先不觸發
  if (includes(route.name, [searchRoute.search, ...categorySearchRoutes]) && isEmptyValue(oldValue)) return;
  inputComplete(newValue);
});

onMounted(() => {
  if (isFocus.value !== true) return;
  searchBarRef.value?.focus();
});

// const validate = (event) => console.log(event);
</script>

<style scoped>
input.ec-form-control {
  border-right: none;
  border-color: theme('colors.gray-200');
  border-radius: 8px;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  padding-left: 1rem;
  color: theme('colors.gray-400');
  &:focus {
    outline: none;
    box-shadow: none;
    color: theme('colors.gray-900');
  }
}
input::placeholder {
  color: theme('colors.gray-400');
  font-size: 14px;
}
button {
  background-color: theme('colors.gold-500');
  @apply tw-border-none tw-rounded-r-lg;

  &:focus,
  &:hover,
  &:active,
  &:focus-visible {
    background-color: theme('colors.gold-400');
    border-color: theme('colors.gold-400');
  }
}
button i {
  color: theme('colors.white');
  padding-left: 18px;
  padding-right: 18px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/mixins/search/auto-complete.scss';
</style>
