<template>
  <BannerBox :show-banner="hasTabs" :title="title" class="carousel">
    <template #subTitle>
      <ul v-if="tabs?.length > 0" class="tabs">
        <li
          v-for="(tab, index) in formattedTabs"
          :id="tab.index !== undefined ? `home-recommend-tags-${tab.index + 1}` : ''"
          :key="index"
          :class="{ active: currentTab === tab.index, empty: !tab.title }"
          @click="tab.index !== undefined && selectTab(tab.index)"
        >
          {{ tab.title || '' }}
        </li>
      </ul>
    </template>
    <template #body>
      <div v-if="isTabActive(currentTab) && !$isEmpty(currentTabData?.products)" :style="`background-color: ${currentTabData?.color};`" class="tw-flex tw-flex-row tw-items-center tw-h-[320px]">
        <div v-for="(product, index) in currentTabData?.products" :key="product.pid" class="product">
          <card :id-data="`home-recommend-products-${currentTab + 1}-${index + 1}`" :product="product" :mode3="true" />
        </div>
        <div v-for="(product, eindex) in Array.from(Array(emptyData).keys())" :key="eindex" class="product"></div>
        <div class="banner">
          <router-link-usage :id="`home-recommend-sb-${currentTab + 1}`" :link="currentTabData.link" :title="currentTabData.alt"
            ><img :src="currentTabData.image" :title="currentTabData.alt"
          /></router-link-usage>
        </div>
      </div>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { isEmptyValue } from '@/helper/data-process';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
  tabs: { type: Array, required: true },
});

const { title, tabs } = toRefs(props);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const first = ref(true);
const currentTab = ref(0);
const loadedTabs = ref(new Set());

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN || x.id || x.guid || x.product_guid, tabs.value[currentTab.value]?.products || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const currentTabData = computed(() => tabs.value[currentTab.value]);
const emptyData = computed(() => (currentTabData.value?.products.length < 3 ? 3 - currentTabData.value?.products.length : 0));

const selectTab = (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return false;
  currentTab.value = index;
  loadedTabs.value.add(index);
  getProductPrices();
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (isEmptyValue(nv) || tabs.value?.length <= 0) return false;
  currentTab.value = first.value ? randomIfNotTest(tabs.value?.length - 1, 0) : 0;
  if (first.value === true) first.value = false;
  selectTab(currentTab.value, false);
  return true;
};

const isTabActive = (index) => loadedTabs.value.has(index);

const hasTabs = computed(() => tabs.value.length > 0);

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.name, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.name, index }));
};

const formattedTabs = computed(() => insertSpaces(tabs.value));

onMounted(() => {
  watch(tabs, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.tabs {
  @apply tw-p-0 tw-m-0 tw-flex tw-justify-around tw-list-none;
}
.tabs li {
  @apply tw-p-2 tw-text-[18px] tw-leading-[22px] tw-font-medium tw-rounded tw-cursor-pointer;
  color: theme('colors.gray-500');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    @apply tw-cursor-default tw-leading-[18px];
    color: theme('colors.gray-200');
    &::before {
      @apply tw-text-[10px];
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
  &.more {
    @apply tw-text-[16px] tw-leading-[22px] tw-font-thin tw-rounded tw-pl-0 tw-cursor-pointer;
    a {
      color: theme('colors.gold-300');
    }
  }
}

.product {
  @apply tw-w-[302px] tw-mx-2 tw-flex;
  &:first-child {
    @apply tw-ml-[34px];
  }
  &:nth-child(3) {
    @apply tw-mr-[34px];
  }
}

.banner {
  @apply tw-flex tw-w-[640px] tw-h-[320px];
  a,
  img {
    @apply tw-w-[640px] tw-max-w-full tw-m-0 tw-p-0;
  }
}
</style>
