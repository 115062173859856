<template>
  <div class="tw-flex tw-flex-col tw-overflow-x-hidden" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus"></customer-service>
    <eslite-header></eslite-header>
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer></eslite-footer>
    <loading v-if="getLoadingStatus"></loading>

    <div class="tw-h-[72px]"></div>
    <TabBar2024Mobile v-if="$screen.isMobileSize && !$inApp" />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useGetCustomerServiceStore } from '@/composables/store/customer-service';
import { useLoadingStore } from '@/stores/loading';
import EsliteFooter from '@/components/layout/footer.vue';
import EsliteHeader from '@/components/layout/pages-header-2024.vue';
import Loading from '@/components/utility/loading.vue';
import TabBar2024Mobile from '@/components/layout/tab-bar.vue';
import { filterUrl } from '@/constant/customer-service';
import { memberProfileStyle } from '@/constant/member-profile';

const CustomerService = defineAsyncComponent(() => import('@/components/customer-service/customer-service.vue'));

const route = useRoute();
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const customerServiceStore = useCustomerServiceStore();
const { getCustomerStatus } = useGetCustomerServiceStore();

const turnOnCustomerStatus = () => customerServiceStore.setCustomerStatus(true);
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
const isCustomerServiceUrl = computed(() => filterUrl.includes(route.fullPath));
const isShowCustomerService = computed(() => getCustomerStatus.value || isCustomerServiceUrl.value);

watch(
  isCustomerServiceUrl,
  (newValue) => {
    if (newValue) turnOnCustomerStatus();
  },
  { immediate: true },
);
</script>

<script>
export default { name: 'Home' };
</script>

<style scoped>
.bgc {
  background-color: theme('colors.gray-50');
  min-height: 100vh;
}
</style>
