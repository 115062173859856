// import { blackLogStyle } from '@/helper/debug';
import { getCookie } from '@/helper/cookie';
/**
 * checkInlineApp
 * 判斷不是 app 內置瀏覽器？ ua: member_tw
 * @returns {boolean}
 */
const checkIsInlineApp = () => {
  try {
    return navigator.userAgent.indexOf('member_tw') > -1;
  } catch (e) {
    return false;
  }
};

export default checkIsInlineApp;

// 是否開啟 "在 app 內不開啟第三方支付" 功能 (cookie 有 third_party_test 時 app 內不顯示第三方支付)
export const hiddenThirdPartyPaymentInAPP = getCookie('third_party_test') === undefined;

/**
 * 確認是否為 android 環境
 * @returns boolean
 */
export const checkIsAndroid = () => navigator.userAgent.match(/android/i) !== null;

/**
 * 確認 tappay redirect 網址是否為街口支付
 * @param {object} query route query object
 * @returns boolean
 */
export const checkIsJKOPay = (query = {}) => {
  const { rec_trade_id: recTradeId } = query;
  return recTradeId?.substr(0, 2) === 'JK' || false;
};

/**
 * 確認 tappay redirect 網址是否為 LINE Pay 支付
 * @param {object} query route query object
 * @returns boolean
 */
export const checkIsLinePay = (query = {}) => {
  const { rec_trade_id: recTradeId } = query;
  return recTradeId?.substr(0, 2) === 'LN' || false;
};

/**
 * 判斷 tappay redirect 網址是否為第三方支付 (JKO Pay, LINE Pay)
 * @param {object} query route object
 * @returns boolean
 */
export const checkIsThirdPartyPayment = (query = {}) => checkIsJKOPay(query) || checkIsLinePay(query);

/**
 * 確認是否為android瀏覽器開啟第三方支付網址 (JKO Pay, LINE Pay)
 * @param {object} route route object
 * @returns boolean
 */
export const checkIsThirdPartyPayOnAndroidBrowser = (route = {}) => {
  const isThirdPartyPayment = checkIsThirdPartyPayment(route.query || {});
  const isAndroid = checkIsAndroid();

  return !checkIsInlineApp() && isThirdPartyPayment && isAndroid;
};
