<template>
  <footer :class="{ bgw: $inApp }" data-test-id="footer">
    <div v-if="!$inApp" class="footer-base">
      <div v-for="(items, index) in webMap" :key="`web-map-${index}`" class="footer-row">
        <div class="tw-flex">
          <div class="tw-w-full tw-flex tw-justify-between">
            <p>{{ items[0] }}</p>
            <div class="button">
              <p class="icon-fa-plus" @click="parentMsg"></p>
              <p class="icon-fa-minus" @click="parentMsg"></p>
            </div>
          </div>
        </div>
        <ul>
          <li v-for="item in items" :key="item.id">
            <a
              v-if="checkIsCustomerService(item.name)"
              :id="item.id"
              :href="item.link"
              target="_blank"
              rel="noreferrer"
              :aria-label="item.id || 'footer-item'"
              data-test-id="footer-contact-button"
              @click.prevent="alertCustomer"
              >{{ item.name }}</a
            >
            <a v-else :id="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id || 'footer-item'">{{ item.name }}</a>
          </li>
        </ul>
        <div class="line"></div>
        <div v-if="items[0] === '訂閱誠品線上電子報'" class="tw-flex tw-mt-12" data-test-id="footer-newsletter-section">
          <div class="tw-w-full tw-flex tw-justify-between tw-flex-col tw-items-stretch">
            <p class="mb-mb">{{ focus.name }}</p>
            <ul class="focus !tw-flex tw-justify-between tw-m-0">
              <li v-for="item in focus.icons" :key="`focus-icons-${item.id}`">
                <a :id="item.id" :key="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id">
                  <img v-lazy="item.icon" :title="item.name" alt="" src="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tw-w-auto">
        <div class="line"></div>
        <div class="tw-flex">
          <!-- app -->
          <div class="tw-w-full tw-flex tw-justify-between tw-flex-col tw-items-stretch">
            <p class="mb-mb">{{ app.name }}</p>
            <ul class="app !tw-flex tw-flex-col tw-m-0" data-test-id="footer-app-section">
              <li v-for="item in app.icons" :key="item.id" class="tw-mb-2">
                <a :id="item.id" :href="item.link" target="_blank" rel="noreferrer" :aria-label="item.id">
                  <img v-lazy="item.icon" :title="item.name" alt="" src="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="line"></div>
        <div class="tw-flex tw-flex-wrap">
          <!-- 資安防護 -->
          <div class="cybersecurity-protection">
            <ul class="qrcode" data-test-id="footer-qrcode-section">
              <li :key="qrCode.icons.id" class="tw-mb-2">
                <a :id="qrCode.icons.id" :href="qrCode.icons.link" target="_blank" rel="noreferrer" :aria-label="qrCode.icons.name">
                  <div class="tw-flex tw-items-end">
                    <div class="qrcode-icon tw-pr-0">
                      <img v-lazy="qrCode.icons.icon" :title="qrCode.icons.name" alt="" src="" />
                    </div>
                    <div class="qrcode-icon tw-ml-2">
                      <span>{{ qrCode.icons.content.first }}<br class="tw-block" />{{ qrCode.icons.content.last }}</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="line dashed tw-my-6"></div>
      <div class="copyright">
        <span>誠品線上eslite.com - powered by 誠品生活 / 誠品書店 / 誠品物流 | 誠品生活股份有限公司 (eslite Spectrum Corporation)</span>
        <br />
        <span>統一編號：27952966 | 台灣台北市信義區松德路204號B1 服務電話：0800-666-798／+886-2-8789-8921</span>
        <div />
        <span>本網站已依台灣網站內容分級規定處理｜建議使用瀏覽器版本：Google Chrome版本60以上 / Firefox版本48以上 / Safari 版本11以上</span>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useCustomerServiceStore } from '@/stores/customer-service';
import { focus2024 as focus, app, qrCode, webMap } from '@/constant/footer';

// data
const customerServiceStore = useCustomerServiceStore();

// methods
const parentMsg = (event) => event.target.parentNode.parentNode.parentNode.classList.toggle('active');
const checkIsCustomerService = (data) => data === '聯絡我們';
const alertCustomer = () => customerServiceStore.setCustomerSwitch(true);
</script>

<script>
export default { name: 'FooterWith2024Index' };
</script>

<style scoped>
footer {
  @apply tw-relative tw-pt-12;
  background-color: theme('colors.gray-80');

  &.bgw {
    background-color: theme('colors.white');
  }

  .button {
    @apply tw-hidden;
  }
  p {
    @apply tw-text-[18px] tw-leading-[22px] tw-mb-2;
    color: theme('colors.gray-900');
  }
  ul li a {
    @apply tw-text-[16px] tw-leading-[22px] tw-font-normal tw-mb-1;
    text-decoration: none;
    color: theme('colors.gray-800');
  }
}

.footer-base {
  @apply tw-w-[1280px] tw-px-0 tw-pt-2 tw-pb-6 tw-mx-auto tw-overflow-hidden tw-flex tw-flex-wrap;
  @apply tw-justify-between tw-flex-row tw-items-stretch;
}
.footer-row {
  @apply tw-w-auto tw-mb-0;
}

.line {
  @apply tw-w-full;
  &.dashed {
    border-top: 1px dashed theme('colors.gray-500');
  }
}

.app {
  img {
    @apply tw-max-w-[140px] tw-max-h-[41.5px];
  }
  li {
    @apply tw-ml-0;
  }
}

.cybersecurity-protection {
  @apply tw-w-full tw-flex tw-justify-between tw-mt-6;
  color: theme('colors.gray-900');
}

.qrcode {
  @apply tw-w-[170px] tw-flex tw-m-0 tw-mt-1;
}

.qrcode img {
  @apply tw-max-w-[70px] tw-max-h-[70px];
}

.qrcode-icon {
  @apply tw-text-[13px] tw-leading-4;
}

.focus {
  @apply tw-w-[120px];
  li {
    @apply tw-mr-0;
  }
  img {
    @apply tw-max-w-12 tw-max-h-12;
  }
}

.copyright {
  @apply tw-w-full tw-text-[12px] tw-leading-4 tw-font-normal tw-text-center tw-my-4;
  color: theme('colors.gray-500');

  div {
    @apply tw-h-2;
  }
}
</style>
