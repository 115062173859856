const emptyCategoryL1 = {
  categoryTranslation: '',
  categoryKey: '',
  categoryColor: '',
};

export function formatCategoryL1Data(l1Category) {
  if (!l1Category) return emptyCategoryL1;
  return {
    categoryTranslation: l1Category.level1Description,
    categoryKey: l1Category.level1Key,
    categoryColor: l1Category.color,
  };
}

/** @const {string[]} sortOrderWithOnlineLeaderboard 排序順序 (B015) */
export const sortOrderWithOnlineLeaderboard = ['中文書', '電子書', '外文書', '童書', '親子用品', '文具潮藝', '服飾鞋包', '美妝保養', '休閒戶外', '生活家居', '食品保健', '3C', '家電'];

/** @const {string[]} sortOrder 排序順序 (B026) */
export const sortOrder = ['電子書', '中文出版', '中文書', '外文出版', '外文書', '兒童親子', '童書', '親子用品', '文具潮藝', '食品日用', '食品保健', '生活家居', '美妝保養', '服飾鞋包', '休閒戶外', '3C家電', '3C', '家電', '影音'];

/** @const {string[]} ignoreOrder 排除內容 */
const ignoreOrder = ['日用', '日文書', '雜誌', '影音'];

/** @const {method} sortByTitle 依照資料內 title 按 sortOrder 資料排序 */
export const sortByTitle = (dataArray, sortData = sortOrderWithOnlineLeaderboard) => {
  const filteredData = dataArray.filter((item) => !ignoreOrder.includes(item.title));

  return filteredData.sort((a, b) => {
    const indexA = sortData.indexOf(a.title);
    const indexB = sortData.indexOf(b.title);

    const rankA = indexA === -1 ? sortData.length : indexA;
    const rankB = indexB === -1 ? sortData.length : indexB;

    return rankA - rankB;
  });
};
