import { map, sortBy, prop, includes, pipe } from 'ramda';
import { emptyReplace } from '@/helper/data-process';
import { productFormatterBase, extraFieldEnum, productFormatterMaker } from '@/helper/product-formatter';
import { homePageBasicFieldFormatter, formatThreadBannerSmall, formatThreadProducts, formatThreadKeywords } from '@/helper/ad/part/home-page-inner-formatter';
import { homePageADKeyEnum } from '@/constant/ad/homepage-ad-type';
import HomeAdProductDto from '@/dto/home-ad-product-dto';

const homePageAdProductBaseFormat = (product) =>
  new HomeAdProductDto(product, {
    formatter: productFormatterBase,
  });

/**
 * 線別標題對應色碼：
 * 位置1: '#545e3a'
 * 位置2: '#0260a6'
 * ...
 * ...
 * 位置9: '#545e3a'
 *
 * 2020.11.20 後正式成為遺跡，改為由後端回傳色碼資料給前端。
 * const threadColorCode = ['#545e3a', '#0260a6', '#ffa311', '#0a8ca8', '#7a4d9a', '#70b102', '#e55970', '#8a6927'];
 */

/**
 * 【首頁廣告 fetch 之後整理資料用的】
 * Formatter 若要用 helper 取 `method` 名
 * 需按 constant/ad/homepage-ad-type.js
 * `format` + `mappingName` 取名
 * 舉例：formatHotTopicImage
 */

/**
 * 流行熱搜
 * @param ad
 * @return {{id, blank, image, alt, page, type, items: f1}}
 */
const formatHotSearch = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  image: emptyReplace(ad.image, ''),
  alt: emptyReplace(ad.alt, ''),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        text: item.title,
        link: item.url,
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

/**
 * 會員 小 banner
 * @param ad
 * @return {{image, blank, alt, link, id, page, type}}
 */
const formatMemberBanner = (ad) => {
  // 多筆
  if (ad.items !== undefined) {
    return {
      ...homePageBasicFieldFormatter(ad),
      items: sortBy(
        prop('sort'),
        map(
          (item) => ({
            id: emptyReplace(item.id, ''),
            alt: emptyReplace(item.alt, ''),
            image: emptyReplace(item.image, ''),
            link: emptyReplace(item.link, '#'),
            sort: emptyReplace(item.sort, 99),
          }),
          emptyReplace(ad.items, []),
        ),
      ),
    };
  }

  // 只給單筆
  return {
    ...homePageBasicFieldFormatter(ad),
    id: emptyReplace(ad.id, ''),
    image: emptyReplace(ad.image, ''),
    alt: emptyReplace(ad.alt, ''),
    link: emptyReplace(ad.link, '#'),
  };
};

/**
 * 注目焦點
 * @param ad
 * @return {{blank, page, type, items: *}}
 */
const formatFocusPoint = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  products: map(homePageAdProductBaseFormat, emptyReplace(ad.products, [])),
  image: emptyReplace(ad.image, ''),
});

/**
 * 大 banner
 * @param ad
 * @return {{blank, page, type, items: f1}}
 */
const formatBigBanner = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        image: emptyReplace(item.image, ''),
        alt: emptyReplace(item.alt, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 99),
        color: emptyReplace(item.color, 'var(--l-gray-f4)'),
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

/**
 * 品牌列表
 * @param ad
 * @return {{blank, page, type, items: *}}
 */
const formatBrandListing = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: sortBy(
    prop('sort'),
    map(
      (item) => ({
        id: item.id,
        image: emptyReplace(item.image, ''),
        alt: emptyReplace(item.alt, ''),
        link: emptyReplace(item.link, '#'),
        sort: emptyReplace(item.sort, 99),
      }),
      emptyReplace(ad.items, []),
    ),
  ),
});

/**
 * 今日最推
 * @param ad
 * @return {{blank, page, type, items: *}}
 */
const formatDailyRecommend = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  products: map(homePageAdProductBaseFormat, emptyReplace(ad.products, []).slice(0, 40)),
});

/**
 * 今日最推V2
 * 分組（類別）
 * @param ad
 */

const formatGroup = map((x) => ({
  groupId: x.id,
  groupTitle: x.title,
  sort: x.sort || 999,
  products: map(homePageAdProductBaseFormat, emptyReplace(x.products, [])),
}));

const formatGroupRecommendItems = pipe(formatGroup, sortBy(prop('sort')));
const formatGroupRecommend = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  group: formatGroupRecommendItems(ad.items || []),
});

/**
 * 好康優惠大b
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatDiscountBannerLarge = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      image: emptyReplace(item.image, ''),
      alt: emptyReplace(item.alt, ''),
      link: emptyReplace(item.link, '#'),
      sort: emptyReplace(item.sort, 99),
    }),
    emptyReplace(ad.items, []),
  ),
});

/**
 * 好康優惠小b
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatDiscountBannerSmall = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      image: emptyReplace(item.image, ''),
      alt: emptyReplace(item.alt, ''),
      link: emptyReplace(item.link, '#'),
      sort: emptyReplace(item.position, 99),
      title: emptyReplace(item.title_1, ''),
      subtitle: emptyReplace(item.title_2, ''),
    }),
    emptyReplace(ad.items, []),
  ),
});

/**
 * 好康優惠專區
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatDiscountArea = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      title: emptyReplace(item.title, ''),
      link: emptyReplace(item.link, '#'),
      position: emptyReplace(item.position, 99),
      products: map(homePageAdProductBaseFormat, emptyReplace(item.products, [])),
    }),
    emptyReplace(ad.items, []),
  ),
});

/**
 * 首頁橫幅
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatHorizontalPaint = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      image: emptyReplace(item.image, ''),
      alt: emptyReplace(item.alt, ''),
      link: emptyReplace(item.link, '#'),
      sort: emptyReplace(item.sort, 99),
    }),
    emptyReplace(ad.items, []),
  ),
});

/**
 * 誠品獨家
 * @param ad
 * @return {{ blank, page, type, products: * }}
 */
const formatEsliteExclusive = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  products: map((x) => {
    const product = homePageAdProductBaseFormat(x);
    product.setKey(homePageADKeyEnum[emptyReplace(ad.banner_type, '')] || '');
    return product;
  }, emptyReplace(ad.products, [])),
});

/**
 * 誠品選書
 * @param ad
 * @return {{ blank, page, type, products: * }}
 */
const formatEsliteBook = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  products: map((x) => {
    const product = homePageAdProductBaseFormat(x);
    product.setKey(homePageADKeyEnum[emptyReplace(ad.banner_type, '')] || '');
    return product;
  }, emptyReplace(ad.products, [])),
});

/**
 * 誠品選樂
 * @param ad
 * @return {{ blank, page, type, products: * }}
 */
const formatEsliteMusic = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  products: map((x) => {
    const product = homePageAdProductBaseFormat(x);
    product.setKey(homePageADKeyEnum[emptyReplace(ad.banner_type, '')] || '');
    return product;
  }, emptyReplace(ad.products, [])),
});

/**
 * 網路暢銷榜
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatOnlineLeaderboard = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: Object.values(
    Object.keys(ad)
      .filter((key) => !includes(key, ['banner_type', 'page', 'blank']))
      .reduce((obj, key) => {
        const newKey = key.replace('L1_ID_', '');
        const extraFields = [extraFieldEnum.preorder, extraFieldEnum.author];
        const productFormatter = productFormatterMaker({ extraFields });
        const products = map(productFormatter, emptyReplace(ad[key], []));

        obj[newKey] = { products, title: newKey };
        return obj;
      }, {}),
  ),
});

/**
 * 品牌週
 * @param ad
 * @return {{ blank, page, type, items: * }}
 */
const formatWeeklyBrands = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      image: emptyReplace(item.image, ''),
      title: emptyReplace(item.title_1, ''),
      subtitle: emptyReplace(item.title_2, ''),
      alt: emptyReplace(item.alt, ''),
      url: emptyReplace(item.link, '#'),
      position: emptyReplace(item.position, 100),
    }),
    emptyReplace(ad.items, []),
  ),
});

/**
 * 主題企劃
 * @param ad
 * @return {{ blank, page, type, image, alt, link, title, items: * }}
 */
const formatMainTopicProject = (ad) => ({
  ...homePageBasicFieldFormatter(ad),
  items: map(
    (item) => ({
      title: emptyReplace(item.title, ''),
      image: emptyReplace(item.image, ''),
      alt: emptyReplace(item.alt, ''),
      url: emptyReplace(item.link, '#'),
      position: item.position,
      products: map(homePageAdProductBaseFormat, emptyReplace(item.products, [])),
    }),
    emptyReplace(ad.items, []),
  ),
});

const formatThreadGroups = (ad) => ({
  type: emptyReplace(ad.banner_type, ''),
  threads: Object.values(
    Object.keys(ad)
      .filter((key) => key !== 'banner_type')
      .reduce((obj, key) => {
        const newKey = parseInt(key.replace('L1_ID_', ''), 10);

        const content = {
          littleBanner: formatThreadBannerSmall(ad[key].find((threadSet) => threadSet.banner_type === 'thread_little_b')),
          keywords: formatThreadKeywords(ad[key].find((threadSet) => threadSet.banner_type === 'thread_keywords')),
          products: formatThreadProducts(ad[key].find((threadSet) => threadSet.banner_type === 'thread_product')),
        };
        obj[key] = { id: newKey, content, category: newKey };
        return obj;
      }, {}),
  ),
});

export default {
  formatBrandListing,
  formatHotSearch,
  formatMemberBanner,
  formatFocusPoint,
  formatBigBanner,
  formatDailyRecommend,
  formatDiscountBannerLarge,
  formatDiscountBannerSmall,
  formatDiscountArea,
  formatHorizontalPaint,
  formatEsliteExclusive,
  formatEsliteBook,
  formatEsliteMusic,
  formatOnlineLeaderboard,
  formatWeeklyBrands,
  formatMainTopicProject,
  formatThreadGroups,
  formatGroupRecommend,
};
