export const categoryTypeEnum = {
  none: 0,
  first: 1,
  second: 2,
  third: 3,
};

export const categoryFetchMethodName = {
  1: 'fetchTopLevelAd',
  2: 'fetchLevel2Ad',
  3: 'fetchLevel3Ad',
};

export const categoryPageLevel = {
  1: 'L1Page',
  2: 'L2Page',
  3: 'L3Page',
};

/** @const {object[]} dictionary Dictionary 中英文對照表 */
export const dictionary = [
  { chinese: '中文書', english: 'chinese' },
  { chinese: '電子書', english: 'ebook' },
  { chinese: '外文書', english: 'foreign' },
  { chinese: '日文書', english: 'japanese' },
  { chinese: '雜誌', english: 'magazine' },
  { chinese: '童書', english: 'childrenbooks' },
  { chinese: '親子用品', english: 'childrenproducts' },
  { chinese: '文具潮藝', english: 'stationery' },
  { chinese: '日用', english: 'daily' },
  { chinese: '服飾鞋包', english: 'clothes' },
  { chinese: '美妝保養', english: 'makeups' },
  { chinese: '休閒戶外', english: 'outdoor' },
  { chinese: '生活家居', english: 'life' },
  { chinese: '食品保健', english: 'food' },
  { chinese: '家電', english: 'appliances' },
  { chinese: '3C', english: '3C' },
  { chinese: '影音', english: 'video' },
];
