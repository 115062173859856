<template>
  <div class="tw-flex tw-flex-col" style="min-height: 100vh">
    <eslite-header />
    <!-- 加側邊欄（category) -->
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer v-if="isShowFooter" />
    <back-to-top v-if="!isShowFooter" />
    <loading v-if="getLoadingStatus" />

    <TabBar2024Mobile v-if="$screen.isMobileSize && !isShowFooter && !$inApp" />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import { useCategoriesStore } from '@/stores/categories';
import EsliteHeader from '@/components/layout/pages-header-2024.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import BackToTop from '@/components/layout/elements/back-to-top.vue';
import Loading from '@/components/utility/loading.vue';
import TabBar2024Mobile from '@/components/layout/tab-bar.vue';

const route = useRoute();
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const isShowFooter = computed(() => route.path.match(/^\/product/) !== null);

const categoriesStore = useCategoriesStore();
const { fetchCategories } = categoriesStore;

onMounted(() => {
  fetchCategories();
});
</script>

<script>
export default { name: 'EsliteLayout' };
</script>
