<template>
  <RouterLinkUsage :link="brand.link" :title="brand.title" class="tw-inline-flex">
    <img :src="brand.image" :alt="brand.title" class="brand-card" />
  </RouterLinkUsage>
</template>

<script setup>
import { toRefs } from 'vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const props = defineProps({
  brand: { type: Object, default: () => {} },
});

const { brand } = toRefs(props);
</script>

<style scoped>
.title {
  @apply tw-h-[60px] tw-pt-2 tw-pb-5;
  @apply tw-text-[24px] tw-leading-7 tw-font-bold;
  border-bottom: 1px dashed theme('colors.gray-400');
}

.brand-card {
  @apply tw-w-[136px] tw-h-[200px];
  @apply tw-flex tw-flex-col tw-justify-between tw-rounded-lg;
}
</style>
