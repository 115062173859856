<template>
  <div ref="appElem" class="tw-flex tw-flex-col" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl, sticky }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus"></customer-service>
    <eslite-header :scroll-mode="sticky" />
    <div class="content-spacer"></div>
    <router-view :scroll-mode="sticky" />
    <loading v-if="getLoadingStatus" />
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useGetCustomerServiceStore } from '@/composables/store/customer-service';
import { useCategoriesStore } from '@/stores/categories';
import EsliteHeader from '@/components/layout/header-2024index.vue';
import Loading from '@/components/utility/loading.vue';
import { filterUrl } from '@/constant/customer-service';
import { memberProfileStyle } from '@/constant/member-profile';
import { fixedScrollAddListeners, fixedScrollRemoveListeners } from '@/helper/fixed-scrolling';

const CustomerService = defineAsyncComponent(() => import('@/components/customer-service/customer-service.vue'));

const route = useRoute();
const categoriesStore = useCategoriesStore();
const { fetchCategories, fetchBrandGroup } = categoriesStore;
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const customerServiceStore = useCustomerServiceStore();
const { getCustomerStatus } = useGetCustomerServiceStore();

const sticky = ref(false);
const appElem = ref(null);

const turnOnCustomerStatus = () => customerServiceStore.setCustomerStatus(true);
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
const isCustomerServiceUrl = computed(() => filterUrl.includes(route.fullPath));
const isShowCustomerService = computed(() => getCustomerStatus.value || isCustomerServiceUrl.value);

watch(
  isCustomerServiceUrl,
  (newValue) => {
    if (newValue) turnOnCustomerStatus();
  },
  { immediate: true },
);

onMounted(() => {
  fixedScrollAddListeners('#header-hidden-anchor', appElem, sticky);
  fetchCategories();
  fetchBrandGroup();
});

onUnmounted(fixedScrollRemoveListeners);
</script>

<script>
export default {
  name: 'HomeWith2024index',
};
</script>

<style>
.content-spacer {
  display: none;
  height: 1rem;
}

.sticky .content-spacer {
  display: block;
}
</style>
