import checkIsInlineApp from '@/helper/check-inline-app';

/**
 * 判斷是否為 app 內開啟網頁
 */
export default ({ app }) => {
  const inApp = checkIsInlineApp();
  app.config.globalProperties.$inApp = inApp;
  app.provide('$inApp', inApp);
};
