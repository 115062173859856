<template>
  <BannerBox :show-banner="hasTabs" :title="title" class="carousel">
    <template #subTitle>
      <ul v-if="tabs?.length > 0" v-show="!hiddenTab" class="tabs">
        <li
          v-for="(tab, index) in formattedTabs"
          :id="tabID(tabData, index)"
          :key="index"
          :class="{ active: currentTab === tab.index, empty: !tab.title }"
          @click="tab.index !== undefined && selectTab(tab.index)"
        >
          {{ tab.title || '' }}
        </li>
      </ul>
    </template>
    <template #body>
      <swiper v-if="isTabActive(currentTab) && !$isEmpty(tabs[currentTab]?.products)" ref="controlledSwiper" :options="swiperOptions" @swiper="onSwiper">
        <swiper-slide v-for="(products, index) in productRow" :key="index" class="product-row">
          <card v-for="(product, pid) in products" :id-data="productID(index, pid)" :key="pid" :product="product" class="product" />
        </swiper-slide>

        <div v-show="productRow.length >= 2" class="swiper-button-prev"></div>
        <div v-show="productRow.length >= 2" class="swiper-button-next"></div>
      </swiper>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import { to2DArray } from '@/helper/grid';
import { isEmptyValue } from '@/helper/data-process';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
  tabs: { type: Array, required: true },
  hiddenTab: { type: Boolean, default: false },
  tabData: { type: String, default: '' }, // tag 欄位的 ga ID 標籤
  productData: { type: String, default: '' }, // product 欄位的 ga ID 標籤
});

const { title, tabs, hiddenTab, tabData, productData } = toRefs(props);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const first = ref(true);
const currentTab = ref(0);
const loadedTabs = ref(new Set());
const controlledSwiper = ref(null);
const swiperInstance = ref(null);

const productRow = computed(() => to2DArray(tabs.value[currentTab.value]?.products || [], 6));
const hasTabs = computed(() => tabs.value.length > 0);

const onSwiper = (swiper) => (swiperInstance.value = swiper);

/** @const {method} tabID tab列的 id */
const tabID = (id = '', index = 0) => (!isEmptyValue(id) ? `${id}-${index + 1}` : '');
/** @const {method} productID 商品 id */
const productID = (row = 0, index = 0) => {
  // 不顯示 tab (tab > 1) => 標籤名稱-tab順序-商品順序
  if (!isEmptyValue(tabData.value) && !isEmptyValue(productData.value)) return `${productData.value}-${currentTab.value + 1}-${row * 6 + index + 1}`;

  // 顯示 tab (tab <= 1) => 標籤名稱-商品順序
  if (isEmptyValue(tabData.value) && !isEmptyValue(productData.value)) return `${productData.value}-${row * 6 + index + 1}`;

  return '';
};

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN || x.guid || x.product_guid, tabs.value[currentTab.value]?.products || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const selectTab = (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return false;
  currentTab.value = index;
  loadedTabs.value.add(index);
  getProductPrices();
  if (swiperInstance.value) {
    swiperInstance.value.slideTo(0, 0);
    swiperInstance.value[productRow.value.length < 2 ? 'disable' : 'enable']();
  }
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (nv === undefined || tabs.value.length <= 0) return false;
  currentTab.value = first.value && tabs.value?.length > 1 ? randomIfNotTest(tabs.value?.length - 1, 0) : 0;
  if (first.value === true) first.value = false;
  selectTab(currentTab.value, false);
  return true;
};

const isTabActive = (index) => loadedTabs.value.has(index);

const swiperOptions = {
  loop: true,
  // autoplay: { delay: 3000, disableOnInteraction: false },
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
};

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.title, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.title, index }));
};

const formattedTabs = computed(() => insertSpaces(tabs.value));

onMounted(() => {
  watch(tabs, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.tabs {
  @apply tw-p-0 tw-m-0 tw-flex tw-justify-around;
  list-style: none;
}
.tabs li {
  @apply tw-p-2 tw-text-[18px] tw-leading-[22px] tw-font-medium tw-rounded tw-cursor-pointer;
  color: theme('colors.gray-500');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    @apply tw-leading-[18px] tw-cursor-default;
    color: theme('colors.gray-200');
    &::before {
      font-size: 10px;
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}

.product-row {
  @apply tw-grid tw-gap-4 tw-grid-cols-6;
}

.carousel:hover {
  .swiper-button-prev,
  .swiper-button-next {
    @apply tw-flex;
  }
}

.swiper-button-prev,
.swiper-button-next {
  top: 82px;
  bottom: auto;
}
</style>
