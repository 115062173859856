<template>
  <BannerBox :show-banner="hasTabs" :title="title" class="carousel">
    <template #subTitle>
      <RouterLinkUsage id="home-top-6-more" link="/best-sellers/online" title="網路暢榜" class="more-link">看更多</RouterLinkUsage>
    </template>
    <template #body>
      <ul v-if="chartWithTitleTranslation?.length > 0" class="tabs">
        <template v-for="(tab, index) in chartWithTitleTranslation" :key="index">
          <li :id="`home-top6-${chineseToEnglish(tab.title)}-${index + 1}`" :class="{ active: index === currentTab }" :attr-category-id="tab.categoryId" @click="selectTab(index)">
            {{ tab.title || ' ' }}
          </li>
          <li v-if="index < chartWithTitleTranslation.length - 1" class="empty"></li>
        </template>
      </ul>

      <ul v-if="hasTabs" class="product-box" :class="{ first: currentTab === 0 }">
        <li v-for="(product, index) in products" :key="index">
          <card :id-data="`home-top6-${chineseToEnglish(chartWithTitleTranslation[currentTab].title)}-${currentTab + 1}-${index + 1}`" :product="product" :mode2="true" :top="index + 1" />
        </li>
      </ul>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { map } from 'ramda';
import { useCategoriesStore } from '@/stores/categories';
import { useHomeAdStore } from '@/stores/home-ad';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { formatCategoryL1Data, sortByTitle, sortOrderWithOnlineLeaderboard } from '@/helper/category/category-data';
import { isEmptyValue } from '@/helper/data-process';
import { dictionary } from '@/constant/category/category-type';
import { randomIfNotTest } from '@/helper/check-image-snapshot-test';

const props = defineProps({
  title: { type: String, default: '' },
});

const { title } = toRefs(props);
const categoriesStore = useCategoriesStore();
const { categoriesQueryGetter, getCategories } = storeToRefs(categoriesStore);
const homeAdStore = useHomeAdStore();
const { getOnlineLeaderboard } = storeToRefs(homeAdStore);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const currentTab = ref(0);
const first = ref(true);

const chartWithTitleTranslation = computed(() => {
  const items = getOnlineLeaderboard.value?.items;
  const categories = getCategories.value;

  if (items && categories.length > 0) {
    return sortByTitle(
      items
        .map((item) => {
          const l1Category = categoriesQueryGetter.value(parseInt(item.title, 10));
          const { categoryKey, categoryTranslation } = formatCategoryL1Data(l1Category);
          const { products } = item;
          // 調整每個 product 並返回修改後的 products
          const updatedProducts = products.map((product) => ({ ...product, title: product.name }));

          return { ...item, products: updatedProducts, categoryId: item.title, gtmKey: categoryKey, title: categoryTranslation };
        })
        .filter((item) => !isEmptyValue(item.title)),
      sortOrderWithOnlineLeaderboard,
    ).slice(0, 13);
  }

  return [];
});

const products = computed(() => (getCategories.value.length > 0 ? chartWithTitleTranslation.value[currentTab.value]?.products.slice(0, 6) : [] || []));
const hasTabs = computed(() => (products.value || []).length > 0 && (getCategories.value || []).length > 0);

const selectTab = (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return false;
  currentTab.value = index;
  const ids = map((x) => x.esliteSN, products.value || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (isEmptyValue(nv) || chartWithTitleTranslation.value?.length <= 0) return false;
  currentTab.value = first.value ? randomIfNotTest(chartWithTitleTranslation.value?.length - 1, 0) : 0;
  if (first.value === true) first.value = false;
  selectTab(currentTab.value, false);
  return true;
};

const chineseToEnglish = (name = '') => dictionary.find((x) => x.chinese === name || '')?.english || '';

onMounted(() => {
  watch(chartWithTitleTranslation, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.more-link {
  color: theme('colors.gold-300');
  @apply tw-text-base tw-leading-[22px] tw-font-medium;
}

.tabs {
  @apply tw-p-0 tw-m-0;
  @apply tw-list-none tw-flex tw-justify-start tw-flex-row tw-items-center tw-flex-nowrap;

  li {
    @apply tw-p-2 tw-text-[18px] tw-leading-[22px] tw-font-medium tw-px-4;
    @apply tw-cursor-pointer;
    color: theme('colors.gray-500');
    &.active {
      @apply tw-rounded-t-[4px];
      background: theme('colors.gold-50');
      color: theme('colors.gold-500');
    }
    &.empty {
      @apply tw-leading-[18px] tw-py-2 tw-px-0;
      @apply tw-cursor-pointer;
      color: theme('colors.gray-200');
      &::before {
        @apply tw-text-[11px];
        content: '';
        border-right: 1px solid theme('colors.gray-200');
      }
    }
  }

  li.empty:has(+ .active)::before,
  li.active + li.empty::before {
    border-right: 1px solid transparent;
  }
}

.product-box {
  @apply tw-px-10 tw-py-6 tw-m-0;
  @apply tw-flex tw-flex-row tw-justify-start;
  background: theme('colors.gold-50');
  border-radius: 8px 8px 8px 8px;

  &.first {
    border-radius: 0px 8px 8px 8px;
  }

  li {
    width: 180px;
    height: 300px;
    margin-right: 24px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
</style>
