import clickOutside from '@/plugins/directives/click-outside';
import imageOnError from '@/plugins/directives/image-on-error';
import gtag from '@/plugins/tracking/gtag';
import gtmPlugin from '@/plugins/tracking/gtm';
import authPlugin from '@/plugins/auth';
import axiosPlugin from '@/plugins/axios';
import cryptoUtilityPlugin from '@/plugins/crypto-utility';
import '@/plugins/styles';
import '@/plugins/day';
import helper from '@/plugins/helper';
import routePlugin from '@/plugins/route';
import veeValidatePlugin from '@/plugins/vee-validate';
import vueFloating from '@/plugins/vue-floating';
import vueLazyload from '@/plugins/vue-lazyload';
import vueMeta from '@/plugins/vue-meta';
import webpSupportChecker from '@/plugins/webp-support-checker';
import windowResize from '@/plugins/window-resize';
import inApp from '@/plugins/in-app';
import localization from '@/plugins/localization';

const plugins = [
  axiosPlugin,
  clickOutside,
  cryptoUtilityPlugin,
  gtag,
  gtmPlugin,
  helper,
  imageOnError,
  localization,
  routePlugin,
  veeValidatePlugin,
  vueFloating,
  vueLazyload,
  vueMeta,
  webpSupportChecker,
  windowResize,
  inApp,
];

export const addPlugins = async (context) => {
  plugins.forEach((plugin) => plugin(context));
  await authPlugin(context);
};
