<template>
  <div v-if="hasProduct" class="product-card">
    <RouterLinkUsage :id="idData" :link="productLink" :title="productAlt">
      <div class="tw-grid tw-grid-rows-[1fr_auto]">
        <!-- 左右區塊 -->
        <div class="tw-flex tw-min-w-[240px] tw-aspect-[240/114]">
          <!-- 左側區塊 -->
          <div class="card-image-box">
            <product-lazy-image :src="productImage" :is-adult="isRestricted" :alt="productAlt" :is-pre-order="isPreOrder" class="card-image" />
            <div v-if="discountPriceOnly" class="card-sale">
              <span class="discount bold font-roboto">{{ discountPriceOnly }}</span>
              <span class="zhe">折</span>
            </div>
            <div v-if="num > 0" class="pennant" :class="{ gray: num > 3 }">
              <span class="top">TOP</span><span class="num font-roboto">{{ num }}</span>
            </div>
          </div>

          <!-- 間隔 -->
          <div class="nbsp"></div>

          <!-- 右側區塊 -->
          <div class="card-text-box">
            <div class="right-top">
              <span>TOP</span><span class="num font-roboto">{{ num }}</span>
            </div>
            <div class="title">{{ productName }}</div>
            <div class="dollar-box font-roboto">
              <span class="sale-dollar">{{ priceFormat(getPrices[productId]) }}</span>
              <span v-if="discountPriceOnly" class="origin-dollar">{{ priceFormat(getPrePrices[productId]) }}</span>
            </div>
          </div>
        </div>
      </div>
    </RouterLinkUsage>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import productPrice from '@/composables/product/product-price';
import { isEmptyValue } from '@/helper/data-process';
import priceFormat from '@/helper/filter/price-format';

const props = defineProps({
  idData: { type: String, default: '' },
  product: { type: Object, default: () => ({}) },
  num: { type: Number, default: 0 },
});
const { idData, product, num } = toRefs(props);
const priceAndImageStore = usePriceAndImageStore();
const { getPrices, getPrePrices, getImages, getType } = storeToRefs(priceAndImageStore);

const productPriceRef = ref(null);

/** @const {string} productId 商品 id */
const productId = computed(() => product.value?.esliteSN || '');
/** @const {string} hasProduct 有商品？ */
const hasProduct = computed(() => !isEmptyValue(productId.value));
/** @const {string} productLink 商品連結 */
const productLink = computed(() => (hasProduct.value === false ? '' : product.value.link || product.value.url || `/product/${productId.value}`));
/** @const {string} productName 標題 */
const productName = computed(() => product.value?.name || '');
/** @const {string} productAlt Alt提示 */
const productAlt = computed(() => product.value?.alt || productName.value);
/** @const {string} productImageType 商品圖片類型 */
const productImageType = computed(() => {
  if (getType.value[productId.value]?.restricted === true) return 'restricted'; // 是18禁商品
  if (getType.value[productId.value]?.restricted === false) return 'default'; // 一般圖片
  return 'loading'; // loading 圖
});
/** @const {boolean} isRestricted 是否為18禁商品 */
const isRestricted = computed(() => productImageType.value === 'restricted' || false);
/** @const {boolean} isPreOrder 是否為預購商品 */
const isPreOrder = computed(() => getType.value[productId.value]?.preOrder || false);
/** @const {boolean} isEbook 是否為電子書 */
const isEbook = computed(() => getType.value[productId.value]?.ebook || false);
/** @const {string} productImage 商品圖 */
const productImage = computed(() => product.value?.image || getImages.value[productId.value]?.medium || '');
const discountPriceOnly = computed(() => productPriceRef.value?.discountPriceOnly || undefined);

onMounted(() => {
  watch(
    productId,
    () => {
      if (!isEmptyValue(productId.value)) productPriceRef.value = productPrice({ id: productId.value, mode: 'price-and-image' });
    },
    { immediate: true },
  );
});
</script>

<style scoped>
.product-card {
  @apply tw-w-full tw-mb-4 tw-bg-white tw-overflow-hidden;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.card-link {
  @apply tw-inline;
}

.card-image-box {
  @apply tw-w-full tw-relative tw-rounded-lg tw-flex tw-items-center tw-justify-center;
  @apply tw-flex-shrink-0 tw-w-[44%] tw-flex tw-items-center tw-justify-center;
}

.card-image {
  @apply tw-w-full tw-h-full tw-object-contain tw-rounded-lg;
  /* outline: 1px solid yellow; */
  @apply tw-pt-[67%];
}

.card-sale {
  @apply tw-absolute tw-top-0 tw-left-0 tw-text-sm tw-px-2 tw-py-1 tw-rounded-tl-lg;
  color: theme('colors.white');
  background: theme('colors.pink-500');
}

.card-text-box {
  @apply tw-w-full tw-m-2 tw-flex tw-flex-col tw-flex-grow tw-justify-center;
  color: theme('colors.gray-800');
}

.nbsp {
  @apply tw-w-[8px] tw-flex-shrink-0;
}

.right-top {
  @apply tw-text-[13px] tw-leading-[22px] tw-font-semibold tw-mb-2;
  @apply tw-flex tw-items-center;
  font-family: Roboto;
  color: theme('colors.gray-800');

  span {
    @apply tw-h-[18px] tw-p-0 tw-m-0 tw-flex tw-items-baseline;
  }
  .num {
    @apply tw-text-[18px] tw-leading-5 tw-font-semibold;
    color: theme('colors.gray-900');
  }
}

.pennant {
  @apply tw-w-[30px] tw-h-[40px] tw-text-[10px] tw-leading-[10px] tw-font-medium tw-flex tw-flex-col;
  @apply tw-absolute tw-top-0 tw-right-3 tw-pt-[2px] tw-mx-auto tw-text-center tw-tracking-[0.8px];
  background: url('@/static/images/icons/pennant-gold.svg') no-repeat;
  color: theme('colors.white');

  &.gray {
    background: url('@/static/images/icons/pennant-gray.svg') no-repeat;
  }

  span.top {
    @apply tw-text-[12px] tw-leading-3 tw-font-medium tw-mx-auto tw-my-0 tw-text-center tw-italic;
    transform: scale(0.9);
  }
  span.num {
    @apply tw-mt-[2px] tw-text-[16px] tw-leading-4 tw-font-bold tw-block  tw-mx-auto tw-my-0 tw-text-center tw-italic;
    font-family: Roboto;
  }
}

.title {
  @apply tw-text-sm tw-line-clamp-2;
}

.product-title {
  @apply tw-w-full tw-h-10 tw-text-sm tw-font-medium tw-line-clamp-2;
  color: theme('colors.gray-900');
}

.dollar-box {
  @apply tw-mt-2 tw-flex tw-items-center;
}

.sale-dollar {
  @apply tw-text-[16px] tw-leading-[22px] tw-not-italic tw-font-bold;
  color: theme('colors.red-600');
  &::before {
    content: '$';
  }
}
.origin-dollar {
  @apply tw-text-[12px] tw-leading-[21px] tw-not-italic tw-font-normal tw-line-through tw-ml-1 tw-flex tw-items-center;
  color: theme('colors.gray-400');
  &::before {
    content: '$';
  }
}

a:active,
a:hover,
a:visited {
  @apply tw-no-underline;

  .product-card-title {
    color: theme('colors.gold-500');
  }
  .sale-dollar {
    color: theme('colors.red-600');
  }
  .origin-dollar {
    @apply tw-line-through;
    color: theme('colors.gray-400');
  }
  .product-card-description {
    color: theme('colors.gray-800');
  }
}

@media (max-width: 360px) {
  .nbsp {
    @apply tw-w-0;
  }
  .title {
    @apply tw-w-4/5 tw-leading-[14px];
  }
}
</style>
