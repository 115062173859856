<template>
  <div class="searchbar">
    <router-link-usage id="logo" link="/" title="誠品線上" class="logo">
      <img src="@/static/images/eslite-logo-icon-32.svg" />
    </router-link-usage>
    <form ref="searchFormRef" method="get" action="/Search" @submit="validate">
      <div class="input-wrapper">
        <i class="icon" />
        <input v-bind="$attrs" v-model="query" type="text" placeholder="請輸入關鍵字" autocomplete="off" aria-label="搜尋" name="query" />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useSearchFacetStore } from '@/stores/search-facet';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { useSearchLocalRecord } from '@/composables/search/local-record';
import { useSearchBarInit } from '@/composables/search/search-bar-init';
import { isEmptyValue } from '@/helper/data-process';
import { gtmSearch } from '@/helper/gtm';
import { bridgewellTrack } from '@/helper/tracking/bridgewell';

const searchFacetStore = useSearchFacetStore();
const $gtm = inject('$gtm');
const query = ref('');
useSearchBarInit({ query });
const { setLocalRecord } = useSearchLocalRecord({ query });

const validate = (event) => {
  if (isEmptyValue(query.value)) {
    event.preventDefault();
    return false;
  }
  // 沒有 auto complete，直接寫入
  setLocalRecord();
  gtmSearch(query.value, $gtm);
  // Note: 如果用宇匯Chrome擴充套件觀察，須在下一行加debugger，再觀查，否則form action直接跳轉search頁面，就看不到目前紀錄
  bridgewellTrack.sendSearch({ searchString: query.value });
  searchFacetStore.clearSearchFacets();
  return true;
};
</script>

<script>
export default {
  name: 'StaticSearchBar',
  inheritAttrs: false,
};
</script>
<style scoped>
.searchbar {
  @apply tw-px-4 tw-py-3 tw-flex;
  background-color: theme('colors.white');
}

.logo {
  @apply tw-w-8 tw-mr-3 tw-flex tw-items-center;
}

form {
  width: calc(100% - 36px);
}

.input-wrapper {
  @apply tw-w-full tw-h-9 tw-bg-white tw-px-4 tw-py-2 tw-rounded-lg tw-relative tw-flex;
  border: 1px solid theme('colors.gray-200');
}
.input-wrapper i {
  @apply tw-w-[18px] tw-h-[18px] tw-absolute tw-left-[10px] tw-top-[50%];
  transform: translateY(-50%);
  pointer-events: none;
  background-image: url('@/static/images/icons/search-md.svg');
}
.input-wrapper input {
  @apply tw-w-full tw-rounded-lg tw-flex-grow tw-outline-none tw-border-none tw-pl-6 tw-text-[14px];
  color: theme('colors.gray-400');
  &:focue {
    outline: none;
    color: theme('colors.gray-900');
  }
}

input::placeholder {
  color: theme('colors.gray-400');
}
</style>
