<template>
  <div :id="`js-affix-link-${cid}`" class="thread-element" :style="`--thread-color: ${useColor.color}; --thread-hover: ${useColor.hover}; --thread-active: ${activityColor};`">
    <div class="header">
      <div class="title" :style="{ backgroundColor: useColor.color }">
        <img src="@/static/images/eslite-logo-icon-36px.svg" />
        <span>{{ name }}</span>
      </div>
      <div class="bg-and-more">
        <div class="bg-line" :style="{ backgroundColor: useColor.color }"></div>
        <RouterLinkUsage v-if="!isEmptyValue(currentData?.link)" :id="`home-${chineseToEnglish(name)}-tags-${currentTab + 1}-more`" :link="currentData?.link" title="看更多" class="more-link"
          >看更多</RouterLinkUsage
        >
      </div>
    </div>
    <div class="body" :style="{ borderColor: useColor.color }">
      <div class="tab">
        <template v-for="(item, index) in formattedTabs" :key="index">
          <div
            :id="`home-${chineseToEnglish(name)}-tags-${index + 1}`"
            class="title hover"
            :class="{ marketing: item.nameType === 'marketing', active: currentTab === index }"
            @mouseover="changeTab(index)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <div class="content">
        <div class="banner">
          <RouterLinkUsage :id="`home-${chineseToEnglish(name)}-tags-${currentTab + 1}-banner`" :link="banner.link" :title="banner.alt" target="_blank"
            ><img :src="banner.image" :alt="banner.alt"
          /></RouterLinkUsage>
        </div>
        <card
          v-for="(product, index) in products"
          :key="index"
          :id-data="`home-${chineseToEnglish(name)}-tags-${currentTab + 1}-product-${index + 1}`"
          :product="product"
          :mode3="true"
          class="product"
        />
        <div v-for="(empty, index) in emptyProducts" :key="index" class="product empty">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import Card from '@/components/product/card-index2024.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { categoryColors, activityColor } from '@/constant/ad/category-colors';
import { isEmptyValue } from '@/helper/data-process';
import { dictionary } from '@/constant/category/category-type';

const props = defineProps({
  name: { type: String, default: '' },
  items: { type: Array, default: () => [] },
  cid: { type: Number, default: 0 },
});

const { name, items, cid } = toRefs(props);

const productLength = 7;
const currentTab = ref(0);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const currentData = computed(() => items.value[currentTab.value]);
const insertSpaces = (arr) => map((item, index) => ({ name: item.name, index, nameType: item.nameType, link: item.link, sort: item.sort }), arr.value || arr);
const formattedTabs = computed(() => insertSpaces(items.value || []).slice(0, 11));
const banner = computed(() => currentData.value?.banner || {});
const products = computed(() => (currentData.value?.products || []).slice(0, productLength));
/** @const {computed} emptyProducts 補充 product 商品數量不足的空格 */
const emptyProducts = computed(() => Array.from({ length: productLength - products.value.length }));

const useColor = computed(() => categoryColors[cid.value] || categoryColors[0]);

const changeTab = (index) => {
  currentTab.value = index;

  const ids = map((x) => x?.esliteSN, products.value || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const chineseToEnglish = (name = '') => dictionary.find((x) => x.chinese === name || '')?.english || '';

onMounted(() => changeTab(0));
</script>

<style scoped>
.thread-element {
  @apply tw-w-[1280px] tw-max-w-full tw-pt-0 tw-px-0 tw-pb-16 tw-m-0;
}

.header {
  @apply tw-h-20 tw-flex tw-items-end tw-cursor-default tw-select-none;

  .title {
    @apply tw-w-[230px] tw-h-20 tw-text-[24px] tw-leading-7 tw-font-bold tw-py-[22px] tw-px-[42px];
    @apply tw-flex tw-flex-row tw-items-center tw-rounded-t-lg tw-whitespace-pre tw-overflow-hidden;
    color: theme('colors.white');

    img {
      @apply tw-w-9 tw-h-9 tw-mr-2 tw-opacity-40;
    }
  }

  .bg-and-more {
    @apply tw-w-[1050px] tw-h-20 tw-flex tw-flex-col-reverse tw-items-end;
  }

  .bg-line {
    @apply tw-w-[1050px] tw-h-5 tw-rounded-tr-lg;
  }

  .more-link {
    color: theme('colors.gold-300');
    @apply tw-w-[54px] tw-text-base tw-leading-[22px] tw-font-normal tw-inline-block tw-text-right tw-mb-4;
  }
}

.body {
  @apply tw-h-[666px] tw-flex;
  @apply tw-rounded-b-lg tw-border-4 tw-border-solid;
  border-top: none;

  .tab {
    @apply tw-w-[226px] tw-h-[664px] tw-py-[34px] tw-flex tw-flex-col;
  }

  .title {
    @apply tw-w-[226px] tw-h-[56px] tw-px-6 tw-py-4 tw-my-[2px] tw-text-center;
    @apply tw-text-[20px] tw-leading-6 tw-font-medium tw-cursor-default;
    color: theme('colors.gray-800');

    &.marketing {
      color: theme('colors.pink-500');
    }

    &:first-child {
      @apply tw-mt-0;
    }
    &:last-child {
      @apply tw-mb-0;
    }

    &.link,
    &.link a,
    &.link a:active,
    &.link a:hover {
      color: var(--thread-active);
    }

    &.hover:hover,
    &.active {
      @apply tw-font-bold;
      color: var(--thread-color);
      background-color: var(--thread-hover);
    }
    &.marketing.hover:hover,
    &.marketing.active {
      @apply tw-font-bold;
      color: theme('colors.pink-500');
    }
  }
}

.content {
  @apply tw-px-5 tw-py-6 tw-flex tw-flex-wrap;
  background-color: theme('colors.gray-50');

  > div {
    @apply tw-h-[300px] tw-col-span-1 tw-mr-4 tw-mb-4 tw-rounded-lg tw-overflow-hidden;

    &:last-child,
    &:nth-child(3) {
      @apply tw-mr-0;
    }
  }

  .product {
    @apply tw-w-[188px];
    &:deep(.image-box),
    &:deep(.product-image) {
      @apply tw-w-[188px] tw-h-[178px] tw-pt-3 tw-px-3 tw-mb-[10px];
      img.product,
      .gray-cover,
      .image-wait-loaded-wrapper {
        @apply tw-w-[164px] tw-h-[164px] tw-m-0;
      }
      .e-aspect-ratio-inner.item-image-wrap {
        width: 164px;
        height: 164px;
        background-color: transparent;
      }
    }
    &:deep(.product-card-text-box) {
      @apply tw-pt-0;
    }
  }

  .banner {
    @apply tw-w-[600px] tw-col-span-3 tw-mr-3 tw-text-center tw-flex tw-justify-center;

    a {
      @apply tw-inline-block;
    }
    img {
      @apply tw-max-w-[600px] tw-h-[300px];
    }
  }
}
</style>
