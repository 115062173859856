<template>
  <BannerBox :show-banner="true" :title="title" class="carousel">
    <template #subTitle></template>
    <template #body>
      <div class="tw-w-full tw-grid tw-grid-cols-6 tw-gap-4">
        <div class="tw-h-[300px] tw-col-span-3">
          <banner-swiper v-if="showBanner" :items="memberOnly" id-data="home-exclusive-b" />
          <div v-else class="loading logo-loading">&nbsp;</div>
        </div>
        <template v-if="banners.length > 0">
          <div v-for="(item, index) in banners" :key="index" class="banner-column">
            <RouterLinkUsage v-if="!$isEmpty(item.link)" :id="`home-exclusive-sb-${index + 1}`" :link="item.link" :title="item.alt" class="slide-banner-img">
              <img class="" :src="item.image" :alt="item.alt" />
            </RouterLinkUsage>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in [0, 1, 2]" :key="index" class="banner-column">
            <div class="slide-banner-img loading logo-loading">&nbsp;</div>
          </div>
        </template>

        <div class="horizontal-banner-column">
          <RouterLinkUsage v-if="showHorizontalBanner" id="home-exclusive-sb-4" :link="horizontalBanner.link" :title="horizontalBanner.alt" class="slide-banner-img">
            <img :src="horizontalBanner.image" :alt="horizontalBanner.alt" />
          </RouterLinkUsage>
          <div v-else class="slide-banner-img loading logo-loading">&nbsp;</div>
        </div>
      </div>
    </template>
  </BannerBox>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import BannerSwiper from '@/components/utility/swiper/banner-swiper.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const homeAdStore = useHomeAd2024Store();
const { getMembersOnly, getSlideAndFourBanner } = storeToRefs(homeAdStore);

const props = defineProps({ title: { type: String, default: '' } });
const { title } = toRefs(props);

const memberOnly = computed(() => getMembersOnly.value?.items || []);
const showBanner = computed(() => memberOnly.value.length > 0 || false);
/** @const {computed} banners 直式banner 3個 */
const banners = computed(() => getSlideAndFourBanner.value?.items || []);
/** @const {computed} horizontalBanner 橫幅腰帶 banner */
const horizontalBanner = computed(() => getSlideAndFourBanner.value?.banner);

const showHorizontalBanner = computed(() => !!horizontalBanner.value?.image);
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.carousel:hover {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
}

.banner-column {
  @apply tw-h-[300px] tw-col-span-1;

  img {
    @apply tw-rounded-lg;
  }
}

.horizontal-banner-column {
  @apply tw-w-full tw-h-[90px] tw-col-span-6 tw-mt-4;

  img {
    @apply tw-rounded-lg;
  }
}
</style>
