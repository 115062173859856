import { ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import routerScrollBehavior from '@/router/router.scrollBehavior.js';
import { useHistoryStore } from '@/stores/history';

const createScrollBehavior = () => {
  return function scrollBehavior(to, from, savedPosition) {
    const historyStore = useHistoryStore();
    historyStore.setSavedPosition(savedPosition);

    return routerScrollBehavior(to, from, savedPosition);
  };
};

export const initRouter = () => {
  const isHistoryPopRef = ref(false);
  /**
   * addEventListener 必須在 createRouter 之前執行，
   * 才會比 vue-router 提早接收到 popstate event。
   * page 才能在 create/setup 階段，正確判斷 isHistoryPop
   */
  window.addEventListener('popstate', () => {
    isHistoryPopRef.value = true;
  });
  const router = createRouter({
    history: createWebHistory(),
    // base: import.meta.env.VITE_BASE_URL,
    routes,
    scrollBehavior: createScrollBehavior(),
  });
  return { router, isHistoryPopRef };
};
