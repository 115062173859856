<template>
  <div v-if="hasData" class="tw-mb-4 wrapper">
    <div :class="useClass" class="icons-row">
      <router-link-usage v-for="(item, index) in linkData.slice(0, 10)" :id="`home-a001-${item.sort}`" :key="index" :link="item.link" :title="item.alt" class="link">
        <span class="icon-wrapper tw-mb-1">
          <img :src="item.image" :alt="item.alt" />
        </span>
        <span class="tw-text-xs tw-h-[18px]">{{ item.alt }}</span>
      </router-link-usage>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const props = defineProps({
  linkData: { type: Array, required: true },
});

const { linkData } = toRefs(props);
const useClass = computed(() => `cols-${linkData.value.slice(0, 10).length || 10}`);
const hasData = computed(() => linkData.value.length > 0);
</script>

<style scoped>
.wrapper {
  @apply tw-px-0 tw-py-5;
  background-color: theme('colors.white');
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.link {
  @apply tw-w-[50px] tw-flex tw-flex-col tw-text-center;
  &:hover {
    text-decoration: none;
  }
  span {
    @apply tw-block;
    word-break: keep-all;
    color: theme('colors.gray-800');
  }
  img {
    @apply tw-w-[50px];
  }
}

.icons-row {
  @apply tw-w-fit tw-grid tw-justify-items-center tw-m-auto tw-gap-y-3;

  &.cols-8 {
    width: 290px;
    @apply tw-grid-cols-4 tw-gap-x-[30px];
  }
  &.cols-9,
  &.cols-10 {
    width: 330px;
    @apply tw-grid-cols-5 tw-gap-x-5;
  }
}
</style>
